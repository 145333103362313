import {
  Ms,
  WaveformIndex,
  WaveformIndexOrUndefined,
} from '@type/ecgEventType/eventUnit';
import { BeatsApiResponse } from '@dtoType/patchEcgs/beats/filter-waveformIndexRange';

/** 웨이브폼 인덱스 기반 구간 데이터 구조 */
export type Range = {
  onsetWaveformIndex: WaveformIndex;
  terminationWaveformIndex: WaveformIndex;
} & { [x: string]: any };

export type OpenableRange =
  | Range
  | {
      onsetWaveformIndex: WaveformIndex;
      terminationWaveformIndex: undefined;
    }
  | {
      onsetWaveformIndex: undefined;
      terminationWaveformIndex: WaveformIndex;
    };

/** 대상 Waveform Index 이 포함된 Range 배열을 필터하는 함수 */
export type InRangeFinder = (
  typeChangedRange: OpenableRange,
  isBeatTypeS: boolean
) => Array<Range>;

/** Beat Event 데이터 구조 또는 undefined */
export type BeatEventOrUndefined = IBeatEvent | undefined;

/** Range 데이터 구조 또는 undefined */
export type RangeOrUndefined = Range | undefined;

export enum EventUpdateDirection {
  PREV,
  NEXT,
  BI,
}

/** Beat Event 의 소스 데이터 구조 */
export type BeatTypeGroup = {
  prevBeatWaveformIndex?: WaveformIndex;
  nextBeatWaveformIndex?: WaveformIndex;
  beatType: number;
  onsetRPeakIndex: WaveformIndex;
  waveformIndexList: Array<WaveformIndex>;
};

/** Beat Event 데이터 구조 */
export interface IBeatEvent {
  onsetRPeakIndex: WaveformIndex;
  ectopicType: string;
  beatType: number;
  waveformIndex: Array<WaveformIndex>;
  onsetWaveformIndex: WaveformIndexOrUndefined;
  hasOnsetMarker: boolean;
  prevExclusive: RangeOrUndefined;
  terminationWaveformIndex: WaveformIndexOrUndefined;
  hasTerminationMarker: boolean;
  nextExclusive: RangeOrUndefined;
  type: string;
}

/** 30초 구간에 대한 Beat 정보와 Beat Event 정보 목록 */
export type BeatsNBeatEvents = {
  createAt: Ms;
  originWaveformIndex: WaveformIndex;
  onsetWaveformIndex: WaveformIndex;
  terminationWaveformIndex: WaveformIndex;
  beats: BeatsApiResponse;
  noises: Array<IBeatEvent>;
  ectopics: Array<IBeatEvent>;
  beatEvents: Array<IBeatEvent>;
};

/** 정규 30초 구간의 BeatsNBeatEvents 관리 구조  */
export type BeatsNBeatEventsList = {
  [x: WaveformIndex]: BeatsNBeatEvents;
}; /** Ectopic API 의 응답 데이터 구조 */
// 코드는 사용되고 있으나 실제로 사용되는 부분이 없음 (IEctopicResponseResult | undefined)

export interface IEctopicResponseResult {
  waveformIndex: Array<WaveformIndex>;
  ectopicType: string;
  beatType: number;
  hrMin: number;
  hrMax: number;
  hrAvg: number;
  position: number;
  beatCount: number;
  isFastest: boolean;
  durationMs: Ms;
  registeredReport: Array<any>;
}
