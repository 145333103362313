import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled, { useTheme } from 'styled-components';

import TextUtil from 'util/TextUtil';

import usePrevious from 'component/hook/usePrevious';

import { DialogWrapper, DialogButtonWrapper } from 'component/ui/dialog/Dialog';
import TextInput from 'component/ui/input/TextInput';
import TextButton from 'component/ui/button/TextButton';

import { type ITheme } from 'theme/StyledComponentsTheme';

interface ResetPasswordDialogProps {
  open: boolean;
  showDialog: (dialogKey: string, params: any, callback: () => void) => void;
  hideDialog: (dialogKey: string) => void;
  passwordState: any;
  handleResetPasswordByEmailRequested: (email: string) => void;
  onClose: () => void;
}

function ResetPasswordDialog(props: ResetPasswordDialogProps) {
  const intl = useIntl();
  const theme = useTheme() as ITheme;

  const {
    open,
    passwordState,
    onClose,
    showDialog,
    hideDialog,
    handleResetPasswordByEmailRequested,
  } = props;

  const [isTryEmail, setIsTryEmail] = useState(false);
  const [email, setEmail] = useState('');

  const prevPasswordState = usePrevious(passwordState) as any;
  useEffect(() => {
    if (
      prevPasswordState &&
      prevPasswordState.pending &&
      !passwordState.pending &&
      isTryEmail
    ) {
      setIsTryEmail(false);
      if (passwordState.error) {
        // showDialog('AlertNetworkDialog');
      } else {
        onClose();
        hideDialog('AccountLockedDialog');

        showDialog(
          'AlertDialog',
          {
            title: intl.formatMessage(
              {
                id: '01-LoginPage-AlertDialog-title-03',
                description:
                  '입력하신 이메일 주소로{br}비밀번호 재설정 링크를 보냈습니다.',
                defaultMessage:
                  '입력하신 이메일 주소로{br}비밀번호 재설정 링크를 보냈습니다.',
              },
              { br: <br /> }
            ),
            message: intl.formatMessage(
              {
                id: '01-LoginPage-AlertDialog-message-03',
                description:
                  '메일이 보이지 않으면 스팸 메일함을 확인해 주세요.{br}찾을 수 없을 경우 고객센터로 문의해 주세요.{br}고객센터 1533-3162 | 영업시간 월~금 09:00~18:00',
                defaultMessage:
                  '메일이 보이지 않으면 스팸 메일함을 확인해 주세요.{br}찾을 수 없을 경우 고객센터로 문의해 주세요.{br}고객센터 1533-3162 | 영업시간 월~금 09:00~18:00',
              },
              { br: <br /> }
            ),
          },
          () => {}
        );
      }
    }
  }, [passwordState.pending]);

  return (
    <DialogWrapper open={open} maxWidth={480}>
      <TitleText>
        {intl.formatMessage({
          id: '99-ResetPasswordDialog-TitleText-children-01',
          description: '비밀번호 재설정하기 / Reset Password',
          defaultMessage: '비밀번호 재설정하기',
        })}
      </TitleText>

      <MessageText>
        {intl.formatMessage(
          {
            id: '99-ForgotPasswordDialog-MessageText-children-01',
            description: '비밀번호 찾기 안내 메시지',
            defaultMessage:
              '등록된 이메일 주소를 입력하시면 해당 주소로 비밀번호 재설정 링크를 보내드립니다. 이메일 주소를 잊으신 경우에는 {email}으로 문의해주세요.',
          },
          { email: 'help@huinno.com' }
        )}
      </MessageText>

      <TextInput
        style={{ width: '100%', marginBottom: '6px' }}
        inputType="email"
        value={email}
        placeholder={intl.formatMessage({
          id: '01-LoginPage-TextInput-placeholder-01',
          description: '이메일',
          defaultMessage: '이메일',
        })}
        onChange={setEmail}
      />
      <DialogButtonWrapper>
        <TextButton
          textColor={theme.color.COOL_GRAY_80}
          title={intl.formatMessage({
            id: '99-Dialog-Button-title-cancel',
            description: '팝업 Dialog의 취소 버튼',
            defaultMessage: '취소',
          })}
          onClick={onClose}
        />
        <TextButton
          disabled={!email || email.length === 0}
          title={intl.formatMessage({
            id: '99-Dialog-Button-title-confirm',
            description: '팝업 Dialog의 확인 버튼',
            defaultMessage: '확인',
          })}
          onClick={() => {
            if (!TextUtil.verifyInRuleForEmail(email)) {
              showDialog(
                'AlertDialog',
                {
                  message: intl.formatMessage({
                    id: '99-AlertDialog-MessageText-children-01',
                    description: '입력한 이메일 형식이 올바르지 않습니다.',
                    defaultMessage: '입력한 이메일 형식이 올바르지 않습니다.',
                  }),
                },
                () => {}
              );
              return;
            }
            handleResetPasswordByEmailRequested(email);
            setIsTryEmail(true);
          }}
        />
      </DialogButtonWrapper>
    </DialogWrapper>
  );
}

const TitleText = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => props.theme.color.BLACK};
`;

const MessageText = styled.div`
  margin: 8px 0 16px 0;
  font-size: 12px;
  color: ${(props) => props.theme.color.BLACK};
`;

export default ResetPasswordDialog;
