import Const from 'constant/Const';

import useShallowEqualSelector from './useShallowEqualSelector';

const { CLIENT_NAME, USER_RELATION_TYPE, ACCESS_LEVEL } = Const;
const IS_CUSTOMER_WEB =
  process.env.REACT_APP_CLIENT_NAME === CLIENT_NAME.WEB_APP;

function useAuthority(args) {
  // FIXME(준호): relationType 기본값으로 internal 로 하는것이 적합?
  const {
    isHospitalAdmin,
    relationType,
    accessLevel,
    ecgTestStatus,
    cloudStatus,
  } = useShallowEqualSelector((state) => ({
    isHospitalAdmin: state.authReducer.user?.isHospitalAdmin ?? false,
    relationType:
      state.authReducer.user?.relationType ?? USER_RELATION_TYPE.INTERNAL,
    accessLevel:
      state.authReducer.user?.accessLevel ?? ACCESS_LEVEL.READ_ONLY.value,
    ecgTestStatus: state.testResultReducer.ecgTest.data?.ecgTestStatus,
    cloudStatus: state.testResultReducer.ecgTest.data?.cloudStatus,
  }));

  const isDone = () => {
    switch (relationType) {
      case USER_RELATION_TYPE.INTERNAL: // Internal
        return IS_CUSTOMER_WEB ? ecgTestStatus === 200 : cloudStatus === 200;
      case USER_RELATION_TYPE.PARTNER: // Partner
        return cloudStatus >= 60;
      case USER_RELATION_TYPE.CUSTOMER: // Customer
        return ecgTestStatus === 200;

      default:
        return;
    }
  };

  return {
    // XXX: 병원 어드민에 인터널 병원 사용자가 로그인한 경우, read only?
    isReadOnly:
      (isHospitalAdmin
        ? false
        : accessLevel === ACCESS_LEVEL.READ_ONLY.value) || isDone(),
    isEditable: accessLevel === ACCESS_LEVEL.READ_WRITE.value,
    isHospitalAdmin,
    relationType,
  };
}

export default useAuthority;
