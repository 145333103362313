import { CHART_CONST, CLASS_NAME_CHART_BASE } from 'constant/ChartConst';
import {
  CHART_EDIT_CONST,
  HIGHCHART_UNIT,
  REPORT_REPRESENTATIVE_STRIP,
  SELECTION_MARKER_TYPE,
  STRIP_TYPE,
  TEN_SEC_STRIP,
  ECG_CHART_UNIT,
  MOUSE_EVENT_TYPE,
  SELECTION_MARKER_OFFSET_CONST,
} from 'constant/ChartEditConst';
import {
  EVENT_CONST_TYPES,
  CLASS_NAME_HUINNO_CONTEXT_MENU_AREA,
  CLASS_HUINNO_EVENT_MARKER_PRIORITY_SELECTED,
} from 'constant/EventConst';
import { PATTERN_MATCHING_CONST } from 'constant/PatternMatchingConst';

import AppColors from 'theme/AppColors';

import { CaliperUtil } from './CaliperUtil';
import { getStringFloat } from './NumberUtil';
import { events } from './PubSubUtil';
import { getEventInfo } from './EventConstUtil';
import eventMarkerRenderer from './ChartRenderUtil/eventMarkerRenderer';
import beatMarkerRenderer from './ChartRenderUtil/beatMarkerRenderer';
import beatButtonRenderer from './ChartRenderUtil/beatButtonRenderer';
import findingRpeakButtonRenderer from './ChartRenderUtil/findingRpeakButtonRenderer';
import chartEdit from './ChartEditUtil/ChartEditUtil';

const THIRTY_SEC_WAVEFORM_LENGTH = ECG_CHART_UNIT.THIRTY_SEC_WAVEFORM_IDX;
const CONST_CLASS_NAME_HUINNO_CONTEXT_MENU_AREA =
  CLASS_NAME_HUINNO_CONTEXT_MENU_AREA;

const {
  renderBeatButtonList,
  renderBeatButton,
  onClickBeatButton,
  onMouseLeaveBeatButton,
  initBeatButtonList,
} = beatButtonRenderer;

const { renderEventMarker, renderHighlighter } = eventMarkerRenderer;

const { renderBeatMarker, _renderBeatMarker } = beatMarkerRenderer;
const { renderFindingRpeakButtonList, renderFindingRpeakButton } =
  findingRpeakButtonRenderer;

const ChartUtil = {
  /**********************************/
  /*  render 10s strip beat button  */
  /**********************************/
  renderBeatButtonList,
  renderBeatButton,
  onClickBeatButton, // 10s strip beat button click callback 함수
  onMouseLeaveBeatButton, // 10s strip beat button mouseleave callback 함수
  initBeatButtonList, // 10s strip beat button 목록 초기화

  /************************/
  /*  render event marker */
  /************************/
  renderEventMarker,
  renderHighlighter, // render event marker에서 사용되는 highlight 부분

  /***********************************************/
  /*  render 30s strip에서 사용하는 beat marker  */
  /***********************************************/
  renderBeatMarker,
  _renderBeatMarker,

  /**********************************************************************/
  /*  render pattern matching mode, 30s strip에서 사용하는 beat marker  */
  /**********************************************************************/
  renderFindingRpeakButtonList,
  renderFindingRpeakButton,
  // 차트 편집 관련
  chartEdit,
  // 캘리퍼 관련
  CaliperUtil,

  renderBorderGrid: (
    chart,
    xAxisTickLength,
    lineWidth,
    xIntervals,
    theme,
    marginTop = 0
  ) => {
    const { x, y, width, height } = chart.plotBox;
    const yIntervals = 6;

    const renderer = chart.renderer;
    const yInterval = (height - lineWidth) / yIntervals;
    const xInterval = (width - lineWidth) / xIntervals;

    // Border
    if (chart.huinnoBorder) {
      chart.huinnoBorder.destroy();
    }
    chart.huinnoBorder = renderer
      .g()
      .attr({ class: 'huinno-chart-grid-g', zIndex: 0 })
      .add();

    renderer
      .rect(x, y, width - lineWidth / 2, height - lineWidth / 2)
      .attr({
        class: 'huinno-chart-border-rect',
        stroke: theme.color.MEDIUM,
        'stroke-width': lineWidth,
        zIndex: 7,
      })
      .add(chart.huinnoBorder);
    renderer
      .path([
        'M',
        x,
        y,
        'V',
        height + xAxisTickLength,
        'M',
        x + width - lineWidth / 2,
        y,
        'V',
        height + xAxisTickLength,
        'Z',
      ])
      .attr({
        className: 'huinno-chart-border-path',
        stroke: theme.color.MEDIUM,
        'stroke-width': lineWidth,
      })
      .add(chart.huinnoBorder);

    // Grid
    if (chart.huinnoGrid) {
      chart.huinnoGrid.destroy();
    }
    chart.huinnoGrid = renderer
      .g()
      .attr({
        class: 'huinno-chart-grid-g',
        zIndex: 0,
      })
      .add();

    const minorYPath = [];
    for (let i = 1; i < yIntervals; i++) {
      minorYPath.push(
        'M',
        x + lineWidth / 2,
        y + lineWidth / 2 + i * yInterval,
        'H',
        width
      );
    }
    renderer
      .path([...minorYPath, 'Z'])
      .attr({
        className: 'huinno-chart-grid-h-path',
        stroke: theme.color.MEDIUM_LIGHT,
        'stroke-width': lineWidth,
      })
      .add(chart.huinnoGrid);

    const minorXPath = [];
    const majorXPath = [];
    for (let i = 1; i < xIntervals; i++) {
      if (i % 5 !== 0) {
        minorXPath.push(
          'M',
          x + lineWidth / 2 + i * xInterval,
          y + lineWidth / 2,
          'V',
          height + marginTop
        );
      } else {
        majorXPath.push(
          'M',
          x + lineWidth / 2 + i * xInterval,
          y + lineWidth / 2,
          'V',
          height + xAxisTickLength
        );
      }
    }
    renderer
      .path([...minorXPath, 'Z'])
      .attr({
        className: 'huinno-chart-grid-v-minor-path',
        stroke: theme.color.MEDIUM_LIGHT,
        'stroke-width': lineWidth,
      })
      .add(chart.huinnoGrid);
    renderer
      .path([...majorXPath, 'Z'])
      .attr({
        className: 'huinno-chart-grid-v-major-path',
        stroke: theme.color.MEDIUM,
        'stroke-width': lineWidth,
      })
      .add(chart.huinnoGrid);
  },
  renderBorderDecoration: (chart) => {
    const {
      x: plotX,
      y: plotY,
      width: plotWidth,
      height: plotHeight,
    } = chart.plotBox;
    const strokeWidth = 0.5;

    if (chart.borderDecoration) {
      chart.borderDecoration.destroy();
    }
    chart.borderDecoration = chart.renderer
      .path()
      .attr({
        fill: 'none',
        'stroke-width': strokeWidth,
        stroke: AppColors.COOL_GRAY_70,
        d: `M 0,0 v ${plotHeight} M ${plotWidth},0 v ${plotHeight}`,
        transform: `translate(${plotX - strokeWidth / 2}, ${
          plotY - strokeWidth / 2
        })`,
        class: `huinno-long-term-border-decoration`,
        zIndex: 2,
      })
      .add();
  },

  /**********************************/
  /*  render patient event button  */
  /**********************************/
  renderPatientEventButton: (
    chart,
    {
      //
      patientEventId,
      position,
      waveformIndex,
      buttonClickEventHandler,
    }
  ) => {
    const { x: plotX, y: plotY, width: plotWidth } = chart.plotBox;
    const widthPerPoint = plotWidth / THIRTY_SEC_WAVEFORM_LENGTH;

    /** width: 16, height: 17 */
    const patientEventMarkerPath =
      'M2 0C0.895431 0 0 0.895431 0 2V10.8915C0 11.5811 0.355238 12.222 0.940001 12.5875L6.94 16.3375C7.58854 16.7428 8.41146 16.7428 9.06 16.3375L15.06 12.5875C15.6448 12.222 16 11.5811 16 10.8915V2C16 0.895431 15.1046 0 14 0H2ZM6.5875 5.4125C6.97917 5.80417 7.45 6 8 6C8.55 6 9.02083 5.80417 9.4125 5.4125C9.80417 5.02083 10 4.55 10 4C10 3.45 9.80417 2.97917 9.4125 2.5875C9.02083 2.19583 8.55 2 8 2C7.45 2 6.97917 2.19583 6.5875 2.5875C6.19583 2.97917 6 3.45 6 4C6 4.55 6.19583 5.02083 6.5875 5.4125ZM4 8.6V10H12V8.6C12.0003 8.317 11.9275 8.05667 11.7815 7.819C11.6355 7.58133 11.4417 7.4 11.2 7.275C10.6833 7.017 10.1583 6.82333 9.625 6.694C9.09167 6.56467 8.55 6.5 8 6.5C7.45 6.49967 6.90833 6.56417 6.375 6.6935C5.84167 6.82283 5.31667 7.01667 4.8 7.275C4.55867 7.39967 4.365 7.58083 4.219 7.8185C4.073 8.05617 4 8.31667 4 8.6Z';
    const xPixel = plotX + widthPerPoint * waveformIndex - 8;
    const yPixel = plotY - 14;

    const pteCoverBaseAttr = {
      class: `patient-icon-back patientTrigger-back-${waveformIndex}`,
      d: patientEventMarkerPath,
      transform: `translate(${xPixel}, ${yPixel})`,
      cursor: 'pointer',
      'pointer-events': 'auto',
      fill: '#fff',
      'fill-opacity': '0.001',
      zIndex: 1,
    };
    const pteCover = chart.renderer
      .path()
      .attr(pteCoverBaseAttr)
      .add(chart.patientButtonGroup);
    pteCover.element.addEventListener('click', function (event) {
      if (!pteButton.isSelected && pteButton.isClickable) {
        buttonClickEventHandler(event);
      }
    });

    const pteButtonBaseAttr = {
      class: `patient-icon-button patientTrigger-button-${waveformIndex}`,
      d: patientEventMarkerPath,
      'fill-rule': 'evenodd',
      'clip-rule': 'evenodd',
      transform: `translate(${xPixel}, ${yPixel})`,
    };
    const pteButton = chart.renderer.path().add(chart.patientButtonGroup);
    pteButton.position = position;
    pteButton.patientEventId = patientEventId;
    pteButton.isReportIncluded = false;
    pteButton.isSelected = false;
    pteButton.isClickable = false;

    const patientButtonStyleLookUp = [
      [
        { fill: '#000000', 'fill-opacity': '0.28' },
        { fill: '#565A5E', 'fill-opacity': '1' },
      ],
      [
        { fill: '#426AFF', 'fill-opacity': '0.5' },
        { fill: '#426AFF', 'fill-opacity': '1' },
      ],
    ];
    pteButton.setPTEButtonState = function (
      isReportIncluded,
      isSelected,
      isClickable
    ) {
      pteButton.attr({
        ...pteButtonBaseAttr,
        ...patientButtonStyleLookUp[Number(isReportIncluded)][
          Number(isSelected)
        ],
      });
      pteCover.attr({
        ...pteCoverBaseAttr,
        'pointer-events': isClickable ? 'auto' : 'none',
      });

      pteButton.isReportIncluded = isReportIncluded;
      pteButton.isSelected = isSelected;
      pteButton.isClickable = isClickable;
    };

    pteButton.setPTEButtonState(false, false, false);
    return pteButton;
  },
  /**
   * 하이차트에 render하는 요소를 g element추가해 하위에 render할 수 있는 환경 세팅
   * 세팅하려고하는 그룹이 이미 존재하는 경우, 해당 그룹을 제거하고 다시 생성합니다.
   *
   * @param {Object} chartInst - 렌더러를 포함하는 차트 인스턴스입니다.
   * @param {Object} options - SVG 그룹에 대한 설정 객체입니다.
   * @param {string | string[]} options.svgGroupProperty - 초기화할 단일 그룹 이름 또는 그룹 이름의 배열입니다.
   * @param {number} [options.zIndex=4] - SVG 그룹의 z-index 값입니다.
   */
  initSVGGroup: (chartInst, { svgGroupProperty: svgGrpProp, zIndex = 4 }) => {
    if (!chartInst) return;

    let svgGrpProps = Array.isArray(svgGrpProp) ? svgGrpProp : [svgGrpProp];
    for (let svgGrpProp of svgGrpProps) {
      if (chartInst[svgGrpProp]) {
        chartInst[svgGrpProp].destroy();
        delete chartInst[svgGrpProp];
      }

      chartInst[svgGrpProp] = chartInst.renderer
        .g()
        .attr({
          class: svgGrpProp,
          zIndex,
        })
        .add();
    }
  },

  /**********************************************************************/
  /*  render pattern matching mode, 30s strip에서 사용하는 beat marker  */
  /**********************************************************************/
  renderPatternMatchingRpeak(
    chartInst,
    {
      type,
      offsetInfo,
      xAxis,
      withRepresentativeSelectMarker,
      theme,
      iconColor: { backgroundColor, star },
      className,
      beatMarkerAddGroupTarget,
    }
  ) {
    const zIndex = 10;
    const xAxisPixel =
      type === HIGHCHART_UNIT.PIXEL
        ? xAxis
        : chartInst.xAxis[0].toPixels(xAxis);

    // icon의 절반을 x축 왼쪽으로 이동시키기 위한 값
    const translateX = xAxisPixel + offsetInfo.TRANSLATE_X_OFFSET;
    const translateY = offsetInfo.TRANSLATE_Y_OFFSET;

    const patternMatchingRpeakIconGroup = chartInst.renderer
      .g()
      .attr({
        class: `${PATTERN_MATCHING_CONST.TEN_SEC_STRIP_PATTERN_MATCHING_RPEAK.CLASS_TITLE.ICON_GROUP}, ${className}`,
        zIndex: 4,
      })
      .add(chartInst[beatMarkerAddGroupTarget]);
    chartInst.renderer
      .rect({
        x: xAxisPixel - 12,
        y: 0.5,
        width: 24,
        height: 24,
        r: 2,
        zIndex: 8,
      })
      .attr({
        id: CHART_EDIT_CONST.PATTERN_MATCHING_RPEAK_RECT,
        zIndex: 9,
        'stroke-width': 1,
        stroke: backgroundColor.stroke,
        fill: backgroundColor.fill,
        fillOpacity: 0.5,
      })
      .add(patternMatchingRpeakIconGroup);

    chartInst.renderer
      .path()
      .attr({
        id: CHART_EDIT_CONST.PATTERN_MATCHING_RPEAK,
        d: PATTERN_MATCHING_CONST.TEN_SEC_STRIP_PATTERN_MATCHING_RPEAK.ICON_SVG
          .STAR,
        transform: `translate(${translateX}, ${translateY}) scale(1.4)`,
        fill: star.fill,
        zIndex,
      })
      .css({
        'pointer-events': 'none',
      })
      .add(patternMatchingRpeakIconGroup);
  },
  initBeatMarkerGroup: (chartInst, { svgGroupProperty }) => {
    if (!chartInst) return;

    const isArray = Array.isArray(svgGroupProperty);
    let svgGroupPropertyList = isArray ? svgGroupProperty : [svgGroupProperty];
    for (let svgGroupProperty of svgGroupPropertyList) {
      if (chartInst[svgGroupProperty]) {
        chartInst[svgGroupProperty].destroy();
        delete chartInst[svgGroupProperty];
      }

      chartInst[svgGroupProperty] = chartInst.renderer
        .g()
        .attr({
          class: svgGroupProperty,
          zIndex: 4,
        })
        .add();
    }
  },
  checkRenderedChart: (chart) => isNaN(chart.xAxis[0].toPixels(0)),

  chartEditStore: {
    [SELECTION_MARKER_TYPE.ONSET]: {
      representativeTimestamp: undefined,
      representativeWaveformIndex: undefined,
      clickedWaveformIndex: undefined,
      clickedTimestamp: undefined,
    },
    [SELECTION_MARKER_TYPE.TERMINATION]: {
      representativeTimestamp: undefined,
      representativeWaveformIndex: undefined,
      clickedWaveformIndex: undefined,
      clickedTimestamp: undefined,
    },
  },

  chartEdit: function () {
    /**
     * @param chartInst
     */
    let chartInst;
    /**
     * @param param2
     * @prop {function} setSelectionStrip
     * @prop {function} setTenSecStrip
     * @prop {number} timestamp
     * @prop {number} representativeWaveformIndex
     * @prop {number} recordingStartMs
     * @prop {object} theme
     */
    let param2,
      setSelectionStrip,
      setTenSecStrip,
      timestamp, // 30sec strip의 representative timestamp
      representativeWaveformIndex,
      recordingStartMs,
      theme;

    if (arguments.length === 1) {
      param2 = arguments[0];
    } else if (arguments.length === 2) {
      chartInst = arguments[0];
      param2 = arguments[1];
    }

    if (!chartInst) {
      chartInst = document.querySelector(
        '.chartContainer .highcharts-plot-background'
      )?.highchartInst;
    }

    if (param2) {
      setSelectionStrip = param2.setSelectionStrip;
      setTenSecStrip = param2.setTenSecStrip;
      timestamp = param2.timestamp;
      representativeWaveformIndex = param2.representativeWaveformIndex;
      recordingStartMs = param2.recordingStartMs;
      theme = param2.theme;
    }

    let _selectionStrip = this.chartEditStore;

    return {
      getSelectionStrip() {
        return _selectionStrip;
      },

      hasOnsetSelectionMarker() {
        return (
          _selectionStrip[SELECTION_MARKER_TYPE.ONSET]
            .representativeTimestamp !== undefined
        );
      },

      hasTerminationSelectionMarker() {
        return (
          _selectionStrip[SELECTION_MARKER_TYPE.TERMINATION]
            .representativeTimestamp !== undefined
        );
      },
      /**
       * selection strip 생성 로직
       *
       * @param {event} 하이차트 클릭 이벤트 객체
       * @param {[Timestamp, Timestamp]} onset, termination selection marker timestamp
       * @returns
       */
      renderSelectionStrip(
        event,
        [onsetRepresentativeTimestamp, terminationRepresentativeTimestamp]
      ) {
        const constOnset = SELECTION_MARKER_TYPE.ONSET;
        const constTermination = SELECTION_MARKER_TYPE.TERMINATION;
        onsetRepresentativeTimestamp =
          _selectionStrip &&
          _selectionStrip[constOnset].representativeTimestamp;
        terminationRepresentativeTimestamp =
          _selectionStrip &&
          _selectionStrip[constTermination].representativeTimestamp;

        if (
          !this._validationSelectionMarker(event, [
            onsetRepresentativeTimestamp,
            terminationRepresentativeTimestamp,
          ])
        ) {
          return;
        }

        const { type, xAxis, clickedWaveformIndex } = event;
        const shiftKey = event.shiftKey || event.isDragging;
        const noiseEventMetaInfo = getEventInfo({
          type: EVENT_CONST_TYPES.NOISE,
        }).findOne();
        let clickedXAxisPixel = event.clickedWaveformIndexPixels;
        let selectionMarkerType;

        if (_isClick()) {
          selectionMarkerType = SELECTION_MARKER_TYPE.ONSET;
          this.removeSelectionMarkerAll();
        } else if (_isShiftClick()) {
          selectionMarkerType = SELECTION_MARKER_TYPE.TERMINATION;

          if (
            _selectionStrip.TERMINATION.clickedWaveformIndex !== undefined &&
            representativeWaveformIndex + clickedWaveformIndex <
              _selectionStrip.ONSET.representativeWaveformIndex +
                _selectionStrip.ONSET.clickedWaveformIndex
          ) {
            this.removeOnsetSelectionMarker({
              chartType: CHART_EDIT_CONST.CHART_TYPE.THIRTY_SEC_STRIP,
            });
          } else {
            this.removeTerminationSelectionMarker({
              chartType: CHART_EDIT_CONST.CHART_TYPE.THIRTY_SEC_STRIP,
            });
          }
        }

        if (selectionMarkerType === SELECTION_MARKER_TYPE.ONSET) {
          _selectionStrip[SELECTION_MARKER_TYPE.ONSET] = {
            selectionMarkerType,
            representativeTimestamp: timestamp,
            representativeWaveformIndex,
            clickedWaveformIndex: Math.round(clickedWaveformIndex),
            extraParam: {
              isNoise: event.target.classList.contains(
                noiseEventMetaInfo?.renderAttrs?.className
              ),
            },
          };
          _selectionStrip[SELECTION_MARKER_TYPE.TERMINATION] = {
            selectionMarkerType: SELECTION_MARKER_TYPE.TERMINATION,
            representativeTimestamp: undefined,
            representativeWaveformIndex: undefined,
            clickedWaveformIndex: undefined,
            extraParam: {
              isNoise: event.target.classList.contains(
                noiseEventMetaInfo?.renderAttrs?.className
              ),
            },
          };
        }

        if (selectionMarkerType === SELECTION_MARKER_TYPE.TERMINATION) {
          if (
            _selectionStrip.ONSET.clickedWaveformIndex !== undefined &&
            _selectionStrip.TERMINATION.clickedWaveformIndex !== undefined &&
            representativeWaveformIndex + clickedWaveformIndex <
              _selectionStrip.ONSET.representativeWaveformIndex +
                _selectionStrip.ONSET.clickedWaveformIndex
          ) {
            _selectionStrip[SELECTION_MARKER_TYPE.ONSET] = {
              selectionMarkerType: SELECTION_MARKER_TYPE.ONSET,
              representativeTimestamp: timestamp,
              representativeWaveformIndex,
              clickedWaveformIndex: Math.round(clickedWaveformIndex),
              extraParam: {
                isNoise: event.target.classList.contains(
                  noiseEventMetaInfo?.renderAttrs?.className
                ),
              },
            };
          } else {
            _selectionStrip[SELECTION_MARKER_TYPE.TERMINATION] = {
              selectionMarkerType,
              representativeTimestamp: timestamp,
              representativeWaveformIndex,
              clickedWaveformIndex: Math.round(clickedWaveformIndex),
              extraParam: {
                isNoise: event.target.classList.contains(
                  noiseEventMetaInfo?.renderAttrs?.className
                ),
              },
            };
          }
        }

        if (
          _selectionStrip.ONSET.clickedWaveformIndex +
            _selectionStrip.ONSET.representativeWaveformIndex >
          _selectionStrip.TERMINATION.clickedWaveformIndex +
            _selectionStrip.TERMINATION.representativeWaveformIndex
        ) {
          const swap = _selectionStrip.ONSET;
          _selectionStrip.ONSET = _selectionStrip.TERMINATION;
          _selectionStrip.ONSET.selectionMarkerType =
            SELECTION_MARKER_TYPE.ONSET;

          _selectionStrip.TERMINATION = swap;
          _selectionStrip.TERMINATION.selectionMarkerType =
            selectionMarkerType = SELECTION_MARKER_TYPE.TERMINATION;
        }

        // selection strip highchart에서 render
        this.renderSelectionMarker({
          xAxis: clickedXAxisPixel,
          type: HIGHCHART_UNIT.PIXEL,
          offsetInfo: SELECTION_MARKER_OFFSET_CONST.THIRTY_SEC_STRIP,
          selectionMarkerType,
        });

        // setSelectionStrip action을 통해서 selection strip 정보 redux store에 저장
        events.subscribe(
          setSelectionStrip.bind(this, {
            selectionMarkerType,
            representativeTimestamp: timestamp,
            representativeWaveformIndex,
            clickedWaveformIndex: Math.round(clickedWaveformIndex),
            extraParam: {
              isNoise: event.target.classList.contains(
                noiseEventMetaInfo?.renderAttrs?.className
              ),
            },
          })
        );

        function _isShiftClick() {
          return (
            (shiftKey &&
              onsetRepresentativeTimestamp !== undefined &&
              terminationRepresentativeTimestamp === undefined) ||
            (shiftKey &&
              onsetRepresentativeTimestamp !== undefined &&
              terminationRepresentativeTimestamp !== undefined)
          );
        }

        function _isClick() {
          return (
            (!shiftKey &&
              onsetRepresentativeTimestamp !== undefined &&
              terminationRepresentativeTimestamp !== undefined) ||
            (!shiftKey && terminationRepresentativeTimestamp === undefined)
          );
        }
      },
      /**
       * render selection marker
       *
       * @param {Object} params - The parameters for rendering the selection marker.
       * @param {string} params.type - HIGHCHART_UNIT.PIXEL or HIGHCHART_UNIT.LOCATION
       * @param {number} params.xAxis - 하이차트 x축 위치(pixel)
       * @param {string} params.selectionMarkerType - SELECTION_MARKER_TYPE.ONSET or SELECTION_MARKER_TYPE.TERMINATION or SELECTION_MARKER_TYPE.RESET
       * @param {any} params.offsetInfo - selection marker를 차트에 render할 요소의 offset 정보(SELECTION_MARKER_OFFSET_CONST 참고)
       * @param {boolean} params.withRepresentativeSelectMarker - 대표 Strip 선택시 보여지는 Marker 의 render 여부
       */
      renderSelectionMarker({
        type,
        xAxis,
        selectionMarkerType,
        offsetInfo,
        withRepresentativeSelectMarker,
      }) {
        const zIndex = 10;
        const strokeWidth = 1;
        const renderAt = offsetInfo.TYPE;
        const plotHeight = chartInst.plotHeight;
        const chartPlotHeight = chartInst.plotHeight;
        const xAxisPixel =
          type === HIGHCHART_UNIT.PIXEL
            ? xAxis
            : chartInst.xAxis[0].toPixels(xAxis);

        // vertical instance
        const yAxisPixel =
          SELECTION_MARKER_OFFSET_CONST.CHART.OFFSET_YAXIS +
          offsetInfo.VERTICAL.YAXIS;
        const vPointPixel = chartPlotHeight + offsetInfo.VERTICAL.HEIGHT;

        // top instance
        const top_translateX =
          xAxisPixel - offsetInfo.SELECTION_MARKER_TOP.XAXIS;
        const top_translateY = offsetInfo.SELECTION_MARKER_TOP.YAXIS;

        // bottom instance
        const bottom_translateX =
          xAxisPixel - offsetInfo.SELECTION_MARKER_BOTTOM.XAXIS;
        const bottom_translateY =
          plotHeight - offsetInfo.SELECTION_MARKER_BOTTOM.YAXIS;

        // Selection Marker Group 생성 - start
        const selectionMarkerGroup = chartInst.renderer
          .g()
          .attr({
            class: `huinno-selectionMarker-${renderAt}`,
            zIndex,
          })
          .add();
        chartInst.renderer
          .path()
          .attr({
            id: 'huinno-selectionMarker-vertical-line',
            d: `M${xAxisPixel} ${yAxisPixel} V${vPointPixel}`,
            clickedChartX: xAxisPixel,
            stroke: theme.color.PRIMARY_BLUE,
            'stroke-width': strokeWidth,
            zIndex,
          })
          .css({
            'pointer-events': 'none',
          })
          .add(selectionMarkerGroup);
        chartInst.renderer
          .path()
          .attr({
            class: 'huinno-selectionMarker-top',
            d: 'M8 2L4 6L-2.62268e-07 2L-3.93402e-07 9.3251e-07L8 9.53674e-07L8 2Z',
            transform: `translate(${top_translateX}, ${top_translateY})`,
            fill: theme.color.PRIMARY_BLUE,
            stroke: theme.color.PRIMARY_BLUE,
            'stroke-width': strokeWidth,
            zIndex,
          })
          .css({
            'pointer-events': 'none',
          })
          .add(selectionMarkerGroup);
        chartInst.renderer
          .path()
          .attr({
            class: 'huinno-selectionMarker-bottom',
            d: 'M2.00115e-07 52L4 48L8 52L8 54L-3.45442e-07 54L2.00115e-07 52Z',
            transform: `translate(${bottom_translateX}, ${bottom_translateY})`,
            fill: theme.color.PRIMARY_BLUE,
            stroke: theme.color.PRIMARY_BLUE,
            'stroke-width': strokeWidth,
            zIndex,
          })
          .css({
            'pointer-events': 'none',
          })
          .add(selectionMarkerGroup);
        chartInst._renderAt = renderAt;
        chartInst._selectionMarkerGroup = selectionMarkerGroup;
        // Selection Marker Group 생성 - end

        const _selectionMarkerUuid =
          CHART_EDIT_CONST.SELECTION_MARKER + selectionMarkerType + renderAt;
        if (!window[_selectionMarkerUuid]) {
          window[_selectionMarkerUuid] = [];
        }
        window[_selectionMarkerUuid].push(...[selectionMarkerGroup]);

        if (withRepresentativeSelectMarker) {
          const selectMarker =
            this.renderRepresentativeSelectMarker(xAxisPixel);
          window[_selectionMarkerUuid].push(selectMarker);
        }
      },
      renderTargetRpeak({
        isFindRpeak,
        selectionMarkerType,
        type,
        offsetInfo,
        xAxis,
        withRepresentativeSelectMarker,
      }) {
        const zIndex = 10;
        const strokeWidth = 1;
        const renderAt = offsetInfo.TYPE;
        const rectSize = 24;
        const imageSize = 14;
        // const plotHeight = chartInst.plotHeight;
        const chartPlotHeight = chartInst.plotHeight;
        const xAxisPixel =
          type === HIGHCHART_UNIT.PIXEL
            ? xAxis
            : chartInst.xAxis[0].toPixels(xAxis);

        // vertical instance
        const yAxisPixel =
          SELECTION_MARKER_OFFSET_CONST.CHART.OFFSET_YAXIS +
          offsetInfo.VERTICAL.YAXIS;
        const vPointPixel = chartPlotHeight + offsetInfo.VERTICAL.HEIGHT;

        const selectionMarkerUuid =
          CHART_EDIT_CONST.SELECTION_MARKER + selectionMarkerType + renderAt;

        if (isFindRpeak) {
          // Remove selection marker
          if (window[selectionMarkerUuid]) {
            window[selectionMarkerUuid].forEach((markerGroup) =>
              markerGroup.destroy()
            );
            delete window[selectionMarkerUuid];
          }
          return;
        }

        // Selection Marker Group 생성 - start
        const selectionMarkerGroup = chartInst.renderer
          .g()
          .attr({
            class: `huinno-selectionMarker-${renderAt}`,
            zIndex,
          })
          .add();
        chartInst.renderer
          .path()
          .attr({
            id: 'huinno-selectionMarker-vertical-line',
            d: `M${xAxisPixel} ${yAxisPixel} V${vPointPixel}`,
            clickedChartX: xAxisPixel,
            stroke: theme.color.PRIMARY_BLUE,
            'stroke-width': strokeWidth,
            zIndex,
          })
          .css({
            'pointer-events': 'none',
          })
          .add(selectionMarkerGroup);

        chartInst.renderer
          .rect({
            x: 0.5, // x
            y: 0.5, // y
            width: rectSize, // width
            height: rectSize, // height
            r: 2, // r
          })
          .attr({
            zIndex: -1,
            fill: theme.color.PRIMARY_BLUE,
            'stroke-width': 1,
            transform: `translate(${xAxisPixel - 12})`,
            stroke: theme.color.PRIMARY_BLUE,
          })
          .add(selectionMarkerGroup);

        chartInst.renderer
          // 이미지를 사용하려면 Public 위치에 필요
          .image(
            '/Icon-Check.svg',
            0.5 + (rectSize - imageSize) / 2,
            0.5 + (rectSize - imageSize) / 2,
            14,
            14
          )
          .attr({
            zIndex: 10,
            fill: theme.color.BLACK,
            'stroke-width': 1,
            transform: `translate(${xAxisPixel - 12})`,
            stroke: theme.color.BLACK,
          })
          .add(selectionMarkerGroup);
        chartInst._renderAt = renderAt;
        chartInst._selectionMarkerGroup = selectionMarkerGroup;
        // Selection Marker Group 생성 - end

        const _selectionMarkerUuid =
          CHART_EDIT_CONST.SELECTION_MARKER + selectionMarkerType + renderAt;
        if (!window[_selectionMarkerUuid]) {
          window[_selectionMarkerUuid] = [];
        }
        window[_selectionMarkerUuid].push(...[selectionMarkerGroup]);

        if (withRepresentativeSelectMarker) {
          const selectMarker =
            this.renderRepresentativeSelectMarker(xAxisPixel);
          window[_selectionMarkerUuid].push(selectMarker);
        }
      },

      /**
       * 대표 Strip 클릭 시 제공되는 Marker
       * @param {number} xAxisPixel 하이차트 x축 위치(pixel)
       */
      renderRepresentativeSelectMarker: (xAxisPixel) => {
        const title = 'Selected Strip';

        const defaultAttrs = {
          // svg 설정 option
          'stroke-width': 0,
          stroke: theme.color.PRIMARY_BLUE,
          r: 2,
          fill: theme.color.PRIMARY_BLUE,
          zIndex: 8,
          padding: 0,
          paddingRight: 3,
          paddingLeft: 3,
          // ⭐️svg 안의 style 설정 option
          style: {
            fontFamily: 'Spoqa Han Sans Neo',
            color: theme.color.WHITE,
            fontSize: '10px',
            lineHeight: '130%',
            fontWeight: 500,
            cursor: 'default',
          },
        };

        const selectMarker = chartInst.renderer
          .button(
            title,
            0, // x
            10, // y
            () => {},
            defaultAttrs,
            defaultAttrs,
            defaultAttrs,
            defaultAttrs
          )
          .attr({
            class: `representative-select-strip-marker-button-${xAxisPixel}`,
          })
          .add();

        const halfOfPatientTriggerWidth = selectMarker.width / 2;

        // ⭐️ selectMarker x축 위치는 아래 align의 x,y property에 의해서 결정됩니다.
        selectMarker.align(
          {
            align: 'left',
            x: xAxisPixel - halfOfPatientTriggerWidth, // ⭐️ patient box 가로 중심이 patient point(ECG point)로 오게 하는 과정.
            y: 1, // ⭐️ 10 char와 patient trigger button의 간격
          },
          false,
          null
        );

        return selectMarker;
      },
      /**
       * render selection highlight
       *
       * @param {string} STRIP_TYPE
       * @param {{ number, number }} selection strip의 selection highlight 영역의 하이차트 waveformIndex
       */
      renderSelectionHighlight(
        stripType,
        { onsetWaveformIndex, terminationWaveformIndex }
      ) {
        const onsetXPoint = chartInst.xAxis[0].toPixels(onsetWaveformIndex);
        const terminationXPoint = chartInst.xAxis[0].toPixels(
          terminationWaveformIndex
        );

        const xAxis = chartInst.xAxis[0];
        const yAxis = chartInst.yAxis[0];

        let leftPosition;
        let topPosition = yAxis.top;
        let rectWidth = xAxis.width;
        let rectHeight = yAxis.height;

        // eslint-disable-next-line default-case
        switch (stripType) {
          case STRIP_TYPE.SINGLE_LINE:
            leftPosition = onsetXPoint;
            rectWidth = terminationXPoint - onsetXPoint;
            break;
          case STRIP_TYPE.MULTI_LINE.ONSET:
            leftPosition = onsetXPoint;
            rectWidth = rectWidth - onsetXPoint;
            break;
          case STRIP_TYPE.MULTI_LINE.MIDDLE:
            leftPosition = 0;
            rectWidth = rectWidth;
            break;
          case STRIP_TYPE.MULTI_LINE.TERMINATION:
            leftPosition = 0;
            rectWidth = terminationXPoint;
            break;
        }

        const _selectionHighLightUuid =
          CHART_EDIT_CONST.SELECTION_HIGHLIGHT + timestamp;
        window[_selectionHighLightUuid] &&
          window[_selectionHighLightUuid]?.destroy();

        chartInst._selectionHighlight = chartInst.renderer
          .rect(
            leftPosition, // x
            topPosition, // y
            rectWidth, // width
            rectHeight // height
          )
          .attr({
            id: `${CHART_CONST.SELECTION_STRIP}-` + timestamp,
            'stroke-width': 0,
            fill: theme.color.PRIMARY_BLUE_FOR_SELECTION,
            'stroke-dashoffset': -300,
            zIndex: 9,
            class: `${CONST_CLASS_NAME_HUINNO_CONTEXT_MENU_AREA}`,
          })
          .add();

        if (!window[CHART_EDIT_CONST.SELECTION_HIGHLIGHT]) {
          window[CHART_EDIT_CONST.SELECTION_HIGHLIGHT] = [];
        }
        window[CHART_EDIT_CONST.SELECTION_HIGHLIGHT].push(
          chartInst._selectionHighlight
        );
      },
      /**
       * 10s Strip Render하기 위한 사전 작업
       *
       * @param {event} 하이차트 클릭 이벤트 객체
       * @param {number} representativeCenterTimeStamp
       * @param {number} representativeCenterWaveformIndex
       */
      renderTenSecStrip(
        event,
        representativeCenterTimeStamp,
        representativeCenterWaveformIndex
      ) {
        try {
          _init.call(this);
          const tenSecStripParam = getTenSecStripParam(
            event,
            representativeCenterTimeStamp,
            representativeCenterWaveformIndex
          );
          events.subscribe(setTenSecStrip.bind(this, tenSecStripParam));

          return window[CHART_EDIT_CONST.MAIN_TEN_SEC_STRIP];

          function _init() {
            this.removeTenSecStrip();
          }
        } catch (error) {
          console.error(error);
        }
      },
      /**
       * render 10s Strip
       *
       * @param {TENSEC_STRIP.TYPE} type
       * @param {TENSEC_STRIP.POSITION} position
       * @param {number} timestamp
       * @param {number} timestamp
       * @param {number} timestamp
       */
      _renderTenSecStrip(
        type,
        position,
        tenSecStripRepresentativeTimestamp,
        tenSecStripOnsetWaveformIndex,
        tenSecStripTerminationWaveformIndex
      ) {
        try {
          const { y: plotBoxY, height: plotBoxHeight } = chartInst.plotBox;
          let startXLocation, topYaxis, width, heightYaxis;
          startXLocation = tenSecStripOnsetWaveformIndex;
          width = chartInst.xAxis[0].toPixels(
            tenSecStripTerminationWaveformIndex - tenSecStripOnsetWaveformIndex
          );
          topYaxis = plotBoxY;
          heightYaxis = plotBoxHeight;

          const xPixel = chartInst.xAxis[0].toPixels(startXLocation);
          const tenSecStripInst = chartInst.renderer
            .rect(xPixel, topYaxis, width, heightYaxis)
            .attr({
              id: `${CHART_CONST.TEN_SEC_STRIP}`,
              stroke: theme.color.PRIMARY_BLUE,
              'stroke-width': 2,
              zIndex: 11,
            })
            .add();
          tenSecStripInst.timestamp = timestamp;

          if (type === TEN_SEC_STRIP.TYPE.MAIN) {
            const mainTenSecSTripInst =
              window[CHART_EDIT_CONST.MAIN_TEN_SEC_STRIP];
            mainTenSecSTripInst?.element && mainTenSecSTripInst.destroy();
            delete window[CHART_EDIT_CONST.MAIN_TEN_SEC_STRIP];

            window[CHART_EDIT_CONST.MAIN_TEN_SEC_STRIP] = tenSecStripInst;
          } else if (type === TEN_SEC_STRIP.TYPE.EXTRA) {
            const extraTenSecStripInst =
              window[CHART_EDIT_CONST.EXTRA_TEN_SEC_STRIP];
            extraTenSecStripInst?.element && extraTenSecStripInst.destroy();
            delete window[CHART_EDIT_CONST.EXTRA_TEN_SEC_STRIP];

            window[CHART_EDIT_CONST.EXTRA_TEN_SEC_STRIP] = tenSecStripInst;
          }
        } catch (error) {
          console.error(error);
        }
      },
      // todo: jyoon[#todo: jyoon [#patternMatching #refactoring] 삭제 변경 방법 필요(inst에 담고 제거, 이렇게 하기 위해서 render시 inst에 설정 필요)
      removeBeatAddPlusButton({ chartRef, isSetRpeak }) {
        const mouseTrackerElement =
          chartRef.current?.container.current.querySelector(
            `.${CHART_EDIT_CONST.MOUSE_TRACKER}`
          );
        const beatAddPlusButton = mouseTrackerElement.querySelector(
          `.${CHART_EDIT_CONST.BEAT_ADD_PLUS_BUTTON}`
        );
        beatAddPlusButton.style.visibility = isSetRpeak ? 'visible' : 'hidden';
      },
      // todo: jyoon[#todo: jyoon [#patternMatching #refactoring] 삭제 변경 방법 필요(inst에 담고 제거, 이렇게 하기 위해서 render시 inst에 설정 필요)
      removeRpeakRangeElement({ chartRef }) {
        const setRpeakRangeElement =
          chartRef.current?.container.current.querySelectorAll(
            `.${CHART_EDIT_CONST.PATTERN_SELECTION_MARKER}-${CHART_EDIT_CONST.CHART_TYPE.TEN_SEC_STRIP}`
          );
        setRpeakRangeElement.forEach((node) => node.remove());
      },
      /**
       * 30s, 10s에서 하이라이트 된 패턴 제거
       */
      removePatternHighlightElement() {
        const patternHighlightArr = document.querySelectorAll(
          `.${CHART_CONST['event-marker']}`
        );
        patternHighlightArr.forEach((node) => node.remove());
      },
      /**
       * render report representative strip
       *
       * @param {number} onsetMs
       * @param {number} terminationMs
       * @param {number} onset
       * @param {number} termination
       * @param {REPORT_REPRESENTATIVE_STRIP.TYPE} type
       */
      renderRepresentativeReportStrip(
        onsetMs,
        terminationMs,
        onset,
        termination,
        type
      ) {
        const { max: maxXaxis } = chartInst.xAxis[0];
        const { top: topYaxis, height: heightYaxis } = chartInst.yAxis[0];
        const wholeLineSecXAxisLocationScale = maxXaxis;

        let startPoint, width;
        if (type === REPORT_REPRESENTATIVE_STRIP.TYPE.WHOLELINE) {
          startPoint = 0;
          width = chartInst.xAxis[0].toPixels(wholeLineSecXAxisLocationScale);
        } else if (type === REPORT_REPRESENTATIVE_STRIP.TYPE.MULTILINE.BEGIN) {
          startPoint = chartInst.xAxis[0].toPixels(onset - onsetMs);
          width = chartInst.xAxis[0].toPixels(
            wholeLineSecXAxisLocationScale - (onset - onsetMs)
          );
        } else if (type === REPORT_REPRESENTATIVE_STRIP.TYPE.MULTILINE.END) {
          startPoint = 0;
          width = chartInst.xAxis[0].toPixels(termination - onsetMs);
        } else if (type === REPORT_REPRESENTATIVE_STRIP.TYPE.MULTILINE.MID) {
          startPoint = 0;
          width = chartInst.xAxis[0].toPixels(wholeLineSecXAxisLocationScale);
        } else if (type === REPORT_REPRESENTATIVE_STRIP.TYPE.ONELINE) {
          startPoint = chartInst.xAxis[0].toPixels(onset - onsetMs);
          width = chartInst.xAxis[0].toPixels(
            wholeLineSecXAxisLocationScale -
              (terminationMs - termination) -
              (onset - onsetMs)
          );
        }

        const representativeReportStripInst = chartInst.renderer
          .rect(startPoint + 1, topYaxis, width - 2, heightYaxis)
          .attr({
            id: 'huinno-representative-report-strip',
            stroke: theme.color.PRIMARY_BLUE,
            'stroke-width': 2,
            zIndex: 11,
          })
          .add();

        if (
          Array.isArray(window[CHART_EDIT_CONST.REPORT_REPRESENTATIVE_STRIP])
        ) {
          window[CHART_EDIT_CONST.REPORT_REPRESENTATIVE_STRIP].push(
            representativeReportStripInst
          );
        } else {
          window[CHART_EDIT_CONST.REPORT_REPRESENTATIVE_STRIP] = [
            representativeReportStripInst,
          ];
        }
      },
      /**
       *
       * move 10s strip
       * @param {TENSEC_STRIP.MOVE_TYPE} type
       * @param {timestamp} onsetRepresentativeTimestamp
       * @param {number} representativeCenterWaveformIndex
       * @param {number} centerWaveformIndex
       * @param {number} sec
       */
      moveTenSecStrip(
        type,
        onsetRepresentativeTimestamp,
        representativeCenterWaveformIndex = undefined,
        centerWaveformIndex,
        sec
      ) {
        let movedRepresentativeCenterTimeStamp, movedOnsetWaveformIndex;

        if (type === TEN_SEC_STRIP.MOVE_TYPE.PREV) {
          const nextWaveformIndex = centerWaveformIndex - 250 * sec; // 250: 1초를 그릴때 필요한 waveformIndex 개수, 10: 10초

          if (nextWaveformIndex < 0) {
            movedRepresentativeCenterTimeStamp =
              onsetRepresentativeTimestamp - 1000 * 30; // 1000 * 30: 1초 * 30초
            movedOnsetWaveformIndex = CHART_CONST.XAXIS_MAX + nextWaveformIndex;
          } else {
            movedRepresentativeCenterTimeStamp = onsetRepresentativeTimestamp;
            movedOnsetWaveformIndex = nextWaveformIndex;
          }
        } else if (type === TEN_SEC_STRIP.MOVE_TYPE.NEXT) {
          const nextWaveformIndex = centerWaveformIndex + 250 * sec;

          if (nextWaveformIndex > CHART_CONST.XAXIS_MAX) {
            movedRepresentativeCenterTimeStamp =
              onsetRepresentativeTimestamp + 1000 * 30;
            movedOnsetWaveformIndex = Math.abs(
              CHART_CONST.XAXIS_MAX - nextWaveformIndex
            );
          } else {
            movedRepresentativeCenterTimeStamp = onsetRepresentativeTimestamp;
            movedOnsetWaveformIndex = nextWaveformIndex;
          }
        }

        this.renderTenSecStrip(
          { clickedWaveformIndex: movedOnsetWaveformIndex },
          movedRepresentativeCenterTimeStamp,
          (movedRepresentativeCenterTimeStamp - recordingStartMs) / 4
        );

        // events.subscribe(
        //   setSelectionStrip.bind(this, {
        //     selectionMarkerType: SELECTION_MARKER_TYPE.ONSET,
        //     representativeTimestamp: moveOnsetTimeStamp,
        //     waveformIndex: moveOnsetWaveformIndex,
        //   })
        // );
      },
      /**
       * 차트에 그려진 selection marker중 제거할 객체를 받아 제거
       *
       * @param {Object} selection Marker 객체
       */
      removeSelectionMarker(selectionMarkerInst) {
        try {
          if (!selectionMarkerInst || selectionMarkerInst.length === 0) return;

          while (selectionMarkerInst.length > 0) {
            const selectionMarkerInstOne = selectionMarkerInst.shift();
            selectionMarkerInstOne.element && selectionMarkerInstOne.destroy();
          }
        } catch (error) {
          console.error(error);
        }
      },
      // 30s strip에 그려진 selection marker 모두 제거
      removeSelectionMarkerAll() {
        this.removeOnsetSelectionMarker({
          chartType: CHART_EDIT_CONST.CHART_TYPE.THIRTY_SEC_STRIP,
        });
        this.removeTerminationSelectionMarker({
          chartType: CHART_EDIT_CONST.CHART_TYPE.THIRTY_SEC_STRIP,
        });
      },
      removeSelectionMarkerAllInTenSecStrip() {
        this.removeOnsetSelectionMarker({
          chartType: CHART_EDIT_CONST.CHART_TYPE.TEN_SEC_STRIP,
        });
        this.removeTerminationSelectionMarker({
          chartType: CHART_EDIT_CONST.CHART_TYPE.TEN_SEC_STRIP,
        });
      },
      // 10, 30s strip에 그려진 onset selection marker 제거
      /**
       *
       * @param {param}
       * @prop {string} chartType CHART_EDIT_CONST.CHART_TYPE.THIRTY_SEC_STRIP, CHART_EDIT_CONST.CHART_TYPE.TEN_SEC_STRIP
       */
      removeOnsetSelectionMarker({ chartType }) {
        this.removeSelectionMarker(
          window[
            CHART_EDIT_CONST.SELECTION_MARKER +
              SELECTION_MARKER_TYPE.ONSET +
              chartType
          ]
        );
      },
      // 10, 30s strip에 그려진 termination selection marker 제거
      /**
       *
       * @param {param}
       * @prop {string} chartType CHART_EDIT_CONST.CHART_TYPE.THIRTY_SEC_STRIP, CHART_EDIT_CONST.CHART_TYPE.TEN_SEC_STRIP
       */
      removeTerminationSelectionMarker({ chartType }) {
        this.removeSelectionMarker(
          window[
            CHART_EDIT_CONST.SELECTION_MARKER +
              SELECTION_MARKER_TYPE.TERMINATION +
              chartType
          ]
        );
      },
      /**
       * selection highlight 제거
       */
      removeSelectionHighlight() {
        try {
          const _selectionStripUuid = CHART_EDIT_CONST.SELECTION_HIGHLIGHT;
          window[_selectionStripUuid] && window[_selectionStripUuid]?.destroy();
        } catch (error) {
          // 이미 삭제된 요소의 삭제 처리임
        }
      },
      /**
       * multi line으로 렌더된 selection highlight 제거
       */
      removeSelectionHighlightAll() {
        try {
          const _selectionStripArr =
            window[CHART_EDIT_CONST.SELECTION_HIGHLIGHT];

          if (!Array.isArray(_selectionStripArr)) return;

          while (_selectionStripArr.length > 0) {
            const one = _selectionStripArr.shift();
            one.element && one.destroy();
          }
        } catch (error) {
          // 이미 삭제된 요소의 삭제 처리임
        }
      },
      /**
       * 10s strip 제거
       */
      removeTenSecStrip() {
        try {
          const tenSecStripInst = window[CHART_EDIT_CONST.MAIN_TEN_SEC_STRIP];
          const extraTenSecStripInst =
            window[CHART_EDIT_CONST.EXTRA_TEN_SEC_STRIP];

          tenSecStripInst?.element && tenSecStripInst.destroy();
          extraTenSecStripInst?.element && extraTenSecStripInst.destroy();

          delete window[CHART_EDIT_CONST.MAIN_TEN_SEC_STRIP];
          delete window[CHART_EDIT_CONST.EXTRA_TEN_SEC_STRIP];
        } catch (error) {
          // 이미 삭제된 요소의 삭제 처리임
        }
      },
      /**
       * report representative strip 제거
       */
      removeRepresentativeReportStrip() {
        try {
          if (
            !Array.isArray(window[CHART_EDIT_CONST.REPORT_REPRESENTATIVE_STRIP])
          )
            return;

          while (
            window[CHART_EDIT_CONST.REPORT_REPRESENTATIVE_STRIP].length !== 0
          ) {
            window[CHART_EDIT_CONST.REPORT_REPRESENTATIVE_STRIP]
              .pop()
              .destroy();
          }
        } catch (error) {
          // 이미 삭제된 요소의 삭제 처리임
        }
      },
      /**
       * chartEdit에서 render한 모든 것들 제거
       */
      removeAll() {
        this.removeSelectionMarkerAll();
        this.removeSelectionHighlightAll();
        this.removeTenSecStrip();
        this.removeRepresentativeReportStrip();
      },
      /**
       *
       * @param {event} 하이차트 클릭 이벤트 객체
       * @param {[Timestamp, Timestamp]} onset, termination selection marker timestamp
       * @returns boolean
       */
      _validationSelectionMarker(event, [onsetInst, terminationInst]) {
        const { shiftKey } = event;
        let result = true;

        if (!onsetInst) {
          if (shiftKey) {
            return (result = false), result;
          } else {
            return result;
          }
        }

        return result;
      },
      /**
       * renderSelectionMarker fn에서 window객체에서 생성하는 hight chart selection strip 객체 반환
       *
       * @param {Object} params
       * @param {CHART_EDIT_CONST.CHART_TYPE.TEN_SEC_STRIP | CHART_EDIT_CONST.CHART_TYPE.THIRTY_SEC_STRIP} params.chartType - chart 타입(10s, 30s)
       * @param {SELECTION_MARKER_TYPE.ONSET | SELECTION_MARKER_TYPE.TERMINATION} params.selectionMarkerType - selection marker 타입(onset, termination)
       * @returns {Object}
       */
      getSelectionMarker({ chartType, selectionMarkerType }) {
        return window[
          CHART_EDIT_CONST.SELECTION_MARKER + selectionMarkerType + chartType
        ];
      },
    };
  },
};

export function getTenSecStripParam(
  event,
  representativeCenterTimeStamp,
  representativeCenterWaveformIndex
) {
  const maxXaxis = CHART_CONST.XAXIS_MAX;
  const { clickedWaveformIndex } = event;
  const MAX_SECOND = 30;

  const xAxisLocationScale = maxXaxis / MAX_SECOND;
  const fiveSecWaveformIndexScale = xAxisLocationScale * 5;

  const startTenSecStripWaveformIndex =
    clickedWaveformIndex - fiveSecWaveformIndexScale;
  const endTenSecStripWaveformIndex =
    clickedWaveformIndex + fiveSecWaveformIndexScale;

  let mainTenSecStrip = {
    type: TEN_SEC_STRIP.TYPE.MAIN,
    position: TEN_SEC_STRIP.POSITION.NONE,
    representativeTimestamp: representativeCenterTimeStamp,
    onsetWaveformIndex: startTenSecStripWaveformIndex,
    terminationWaveformIndex: endTenSecStripWaveformIndex,
  };

  let extraTenSecStrip = {
    type: TEN_SEC_STRIP.TYPE.EXTRA,
    position: TEN_SEC_STRIP.POSITION.NONE,
    representativeTimestamp: undefined,
    onsetWaveformIndex: undefined,
    terminationWaveformIndex: undefined,
  };

  // setting - mainTenSecStrip
  if (startTenSecStripWaveformIndex < 0) {
    mainTenSecStrip.onsetWaveformIndex = 0;
    mainTenSecStrip.terminationWaveformIndex = endTenSecStripWaveformIndex;
  } else if (endTenSecStripWaveformIndex > maxXaxis) {
    mainTenSecStrip.onsetWaveformIndex = startTenSecStripWaveformIndex;
    mainTenSecStrip.terminationWaveformIndex = maxXaxis;
  }

  // setting - extraTenSecStrip
  if (startTenSecStripWaveformIndex < 0) {
    Object.assign(extraTenSecStrip, {
      position: TEN_SEC_STRIP.POSITION.PREV,
      representativeTimestamp: representativeCenterTimeStamp - 1000 * 30,
      onsetWaveformIndex: maxXaxis + startTenSecStripWaveformIndex,
      terminationWaveformIndex: maxXaxis,
    });
  } else if (endTenSecStripWaveformIndex > maxXaxis) {
    Object.assign(extraTenSecStrip, {
      position: TEN_SEC_STRIP.POSITION.NEXT,
      representativeTimestamp: representativeCenterTimeStamp + 1000 * 30,
      onsetWaveformIndex: 0,
      terminationWaveformIndex: endTenSecStripWaveformIndex - maxXaxis,
    });
  }

  const tenSecStripParam = {
    representativeCenterTimeStamp: representativeCenterTimeStamp,
    representativeCenterWaveformIndex: representativeCenterWaveformIndex,
    centerWaveformIndex: clickedWaveformIndex,
    [TEN_SEC_STRIP.TYPE.MAIN]: mainTenSecStrip,
    [TEN_SEC_STRIP.TYPE.EXTRA]: extraTenSecStrip,
  };
  return tenSecStripParam;
}

/**
 * ECG 데이터를 시각화한 Highcharts 의 chartX 값을 **localWaveformIndex 로 변환**
 *
 * @param {number} chartX 렌더된 Highcharts Element 의 X 축 좌표값
 * @param {{x:number, y:number, width:number, height:number}} plotBox 렌더된 Highcharts Inst. 의 property 중 plotBox
 * @param {number} waveformLength 렌더된 Highcharts Element 에 사용된 ECG Waveform 길이
 * @return {number} chartX 에 해당하는 **localWaveformIndex**, 오류 시 -1
 */
export function transformSystemChartXToWaveformIndex(
  chartX,
  plotBox,
  waveformLength
) {
  try {
    const aWaveformWidth = plotBox.width / waveformLength;
    const plotX = chartX - plotBox.x;
    return Math.round(plotX / aWaveformWidth);
  } catch (error) {
    console.error('transformSystemChartXToWaveformIndex', error);
  }
  return -1;
}

/**
 * localWaveformIndex 값을 ECG 데이터를 시각화한 Highcharts 의 **chartX 로 변환**
 *
 * @param {number} localWaveformIndex 렌더된 Highcharts Element 에 사용된 ECG Waveform 중 특정 index
 * @param {{x:number, y:number, width:number, height:number}} plotBox 렌더된 Highcharts Inst. 의 property 중 plotBox
 * @param {number} waveformLength 렌더된 Highcharts Element 에 사용된 ECG Waveform 길이
 * @return {number} localWaveformIndex 에 해당하는 **chartX**, 오류 시 -1
 */
export function transformSystemWaveformIndexToChartX(
  localWaveformIndex,
  plotBox,
  waveformLength
) {
  try {
    const aWaveformWidth = plotBox.width / waveformLength;
    return Math.round(localWaveformIndex * aWaveformWidth + plotBox.x);
  } catch (error) {
    console.error('transformSystemWaveformIndexToChartX', error);
  }
  return -1;
}

export const ECGChartCommonOption = {
  tooltip: {
    enabled: false,
    formatter: function () {
      return `${this.x / 250}sec ${getStringFloat(this.y, 2)} mV`;
    },
  },
  boost: {
    // Need to consider whether use
    // enabled: true,
    useGPUTranslations: true,
    // usePreallocated: true,
  },
  title: null,
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
};

const xAxisFrom = -100;
const xAxisTo = THIRTY_SEC_WAVEFORM_LENGTH + 100;
const xAxisStep = 50;
const yAxisFrom = -3;
const yAxisTo = 4;
const yAxisStep = 0.5;
const isHalfEven = (value) => Math.floor(value / 2) % 2 === 0;
export const ecgChartGridSeries30 = [
  {
    data: (() => [
      ...Array.from({ length: 2 * 15 }, (v, k) => [
        isHalfEven(k + 1) ? xAxisFrom : xAxisTo,
        yAxisFrom + yAxisStep * Math.floor(k / 2),
      ]),
      ...Array.from({ length: 2 * 3 * 10 * 4 }, (v, k) => [
        xAxisStep * (Math.floor(k / 2) + Math.floor(k / 2 / 4) + 1),
        isHalfEven(k + 1) ? yAxisTo : yAxisFrom,
      ]),
    ])(),
    className: `huinno-minor-grid ${CLASS_NAME_CHART_BASE}`,
    name: 'huinno-grid',
    lineWidth: 0.5,
    animation: false,
    connectEnds: false,
    color: AppColors.COOL_GRAY_40,
    zoneAxis: 'x',
    enableMouseTracking: false,
    zIndex: -1,
    isGridSeries: true,
  },
  {
    data: (() => [
      ...Array.from({ length: 2 * 3 * 9 }, (v, k) => [
        xAxisStep * 5 * (Math.floor(k / 2) + Math.floor(k / 2 / 9) + 1),
        isHalfEven(k + 1) ? yAxisTo : yAxisFrom,
      ]),
    ])(),
    className: `huinno-normal-grid ${CLASS_NAME_CHART_BASE}`,
    name: 'huinno-grid',
    lineWidth: 0.5,
    animation: false,
    connectEnds: false,
    color: AppColors.COOL_GRAY_50,
    zoneAxis: 'x',
    enableMouseTracking: false,
    zIndex: -1,
    isGridSeries: true,
  },
  {
    data: (() => [
      ...Array.from({ length: 2 * 4 }, (v, k) => [
        xAxisStep * 5 * 10 * Math.floor(k / 2),
        isHalfEven(k + 1) ? yAxisTo : yAxisFrom,
      ]),
    ])(),
    className: `huinno-major-grid ${CLASS_NAME_CHART_BASE}`,
    name: 'huinno-grid',
    lineWidth: 0.5,
    animation: false,
    connectEnds: false,
    color: AppColors.COOL_GRAY_70,
    zoneAxis: 'x',
    enableMouseTracking: false,
    zIndex: -1,
    isGridSeries: true,
  },
];
export const ecgChartGridSeries10 = [
  {
    data: (() => [
      ...Array.from({ length: 2 * 15 }, (v, k) => [
        isHalfEven(k + 1) ? xAxisFrom : xAxisTo,
        yAxisFrom + yAxisStep * Math.floor(k / 2),
      ]),
      ...Array.from({ length: 2 * 10 * 4 }, (v, k) => [
        xAxisStep * (Math.floor(k / 2) + Math.floor(k / 2 / 4) + 1),
        isHalfEven(k + 1) ? yAxisTo : yAxisFrom,
      ]),
    ])(),
    className: `huinno-minor-grid ${CLASS_NAME_CHART_BASE}`,
    name: 'huinno-grid',
    lineWidth: 0.5,
    animation: false,
    connectEnds: false,
    color: AppColors.COOL_GRAY_40,
    zoneAxis: 'x',
    enableMouseTracking: false,
    zIndex: -1,
  },
  {
    data: (() => [
      ...Array.from({ length: 2 * 11 }, (v, k) => [
        xAxisStep * 5 * Math.floor(k / 2),
        isHalfEven(k + 1) ? yAxisTo : yAxisFrom,
      ]),
    ])(),
    className: `huinno-normal-grid ${CLASS_NAME_CHART_BASE}`,
    name: 'huinno-grid',
    lineWidth: 0.5,
    animation: false,
    connectEnds: false,
    color: AppColors.COOL_GRAY_50,
    zoneAxis: 'x',
    enableMouseTracking: false,
    zIndex: -1,
  },
];

/**
 * ms 단위인 interval로 bpm 구하기
 *
 * @param {*} interval
 * @returns
 */
export function convertMsToBpm(interval) {
  if (interval === 0) return 0;

  // 공식 : 60 * 1000 / RR-Interval
  const BASE_SIXTY = 60;
  const ONE_SEC_TO_MILLISECONDS = 1000;

  // MEMO 서비스는 HR계산시 소수점 첫번째 자리 반올림 (sync with BackEnd)
  return Math.round((BASE_SIXTY * ONE_SEC_TO_MILLISECONDS) / interval);
}

export function convertMsToSec(ms) {
  return ms / 1000;
}

export function isRightClick(event) {
  return (
    event.button === MOUSE_EVENT_TYPE.RIGHT_CLICK_BUTTON ||
    event.buttons === MOUSE_EVENT_TYPE.RIGHT_CLICK_BUTTON
  );
}

export function isLeftClick(event) {
  return event.buttons === MOUSE_EVENT_TYPE.LEFT_CLICK_BUTTON;
}

export default ChartUtil;
