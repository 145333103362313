import styled from 'styled-components';

const Timeline = ({ longitudinalDomesticList }) => {
  return (
    <TimelineWrapper>
      <ScrollContainer>
        <BackgroundLine />
        {longitudinalDomesticList.toReversed().map((item, index) => (
          <TimelineItem key={index}>
            <DotWrapper>
              <OuterDot />
              <Dot isFirst={index === 0} />
            </DotWrapper>
            <Content>
              <Status>
                [{item.nowLc}] {item.processSttus}
              </Status>
              <Time>
                {item.dlvyDate} ({getDayOfWeek(item.dlvyDate)}) {item.dlvyTime}
              </Time>
            </Content>
          </TimelineItem>
        ))}
      </ScrollContainer>
    </TimelineWrapper>
  );
};

const getDayOfWeek = (dateString) => {
  const days = ['일', '월', '화', '수', '목', '금', '토'];
  const date = new Date(dateString);
  return days[date.getDay()];
};

const TimelineWrapper = styled.div`
  position: relative;
  max-height: 232px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  position: relative;
`;

const BackgroundLine = styled.div`
  position: absolute;
  left: 13px;
  top: 0;
  width: 4px;
  height: 100%;
  background-color: ${({ theme }) => theme.color.COOL_GRAY_30};
`;

const TimelineItem = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const DotWrapper = styled.div`
  position: relative;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OuterDot = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  z-index: 1;
`;

const Dot = styled.div<{ isFirst: boolean }>`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${({ isFirst, theme }) =>
    isFirst ? theme.color.BLUE_70 : theme.color.COOL_GRAY_60};
  position: absolute;
  z-index: 2;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  gap: 2px;
`;

const Status = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.COOL_GRAY_90};
`;

const Time = styled.div`
  font-size: 11px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.BLUE_GRAY};
`;

export default Timeline;
