import styled from 'styled-components';

import { PARCEL_STATUS } from 'component/dialog/ManageParcelDialog/ParcelConst';

const { NONE, CANCELED } = PARCEL_STATUS;

function Title({ isEditable, status, trackingNumber }) {
  function getParcelTitle(parcelStatus) {
    switch (parcelStatus) {
      case NONE.value:
        return '택배 예약하기';
      case CANCELED.value:
        return '택배 예약 취소';
      default:
        return '택배 예약 정보';
    }
  }

  return (
    <Wrapper>
      <TitleText>
        {getParcelTitle(status)}
        {trackingNumber && ' / 배송 조회'}
      </TitleText>

      {isEditable && (
        <SubTitleText>
          정확한 정보를 입력해주세요. 오류시 택배 수거가 원활하지 않을 수
          있습니다.
        </SubTitleText>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const TitleText = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 130%;
  height: 24px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.COOL_GRAY_90};
`;

const SubTitleText = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.color.COOL_GRAY_80};
`;

export default Title;
