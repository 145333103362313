import styled from 'styled-components';
import ReactDatePicker from 'react-datepicker';
import { useEffect, useState } from 'react';

import DateUtil from 'util/DateUtil';

import ApiManager from 'network/ApiManager/ApiManager';

import { StyledDialogRowItem, StyledTextInput } from './FormInput';
import Label from './Label';
import { PARCEL_STATUS } from './ParcelConst';
import ParcelDatePickerCustomHeader from './ParcelDatePickerCustomHeader';

function DatePickerInput({ isEditable, pickUpDate, ecgTest, onChangeInput }) {
  const { formatDateOnly, getUserLocationTime } = DateUtil;
  const [holidayDates, setHolidayDates] = useState([]);

  useEffect(() => {
    const fetchHolidays = async () => {
      try {
        await ApiManager.getHolidays((_, res) => {
          setHolidayDates(res.result);
        });
      } catch (error) {
        console.error('Failed to fetch holidays', error);
      }
    };

    fetchHolidays();
  }, []);

  const isWeekday = (date) => {
    const day = date.getDay();
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth()는 0부터 시작하므로 1을 더함
    const dayOfMonth = date.getDate();

    // 주말 체크 (0: 일요일, 6: 토요일)
    if (day === 0 || day === 6) return false;

    // 공휴일 체크
    const holidays = holidayDates[year]?.[month] || [];
    if (holidays.includes(dayOfMonth)) return false;

    return true;
  };

  const handleDateChange = (date) => {
    onChangeInput(formatDateOnly(date), 'pickUpDate');
  };

  if (!isEditable) {
    const isCanceledStatus =
      ecgTest.parcel.status === PARCEL_STATUS.CANCELED.value;
    if (isCanceledStatus) return null;
    return <Label label={'택배 수거일'} value={pickUpDate} />;
  }

  return (
    <StyledDialogRowItem label={'택배 수거일'} required>
      <DatePickerWrapper>
        <ReactDatePicker
          dateFormat="yyyy-MM-dd"
          dateFormatCalendar="yyyy년 L월"
          formatWeekDay={formatWeekDay}
          popperPlacement="bottom"
          customInput={<StyledTextInput value={pickUpDate} />}
          minDate={
            ecgTest.estimatedEndDatetime
              ? new Date(ecgTest.estimatedEndDatetime)
              : getUserLocationTime()
          }
          selected={new Date(pickUpDate)}
          filterDate={isWeekday}
          onChange={handleDateChange}
          disabledKeyboardNavigation
          renderCustomHeader={({
            changeMonth,
            changeYear,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => {
            return (
              <ParcelDatePickerCustomHeader
                /* standard props */
                changeYear={changeYear} // 년도 변경
                decreaseMonth={decreaseMonth} // 이전 달로 이동
                increaseMonth={increaseMonth} // 다음 달로 이동
                prevMonthButtonDisabled={prevMonthButtonDisabled} // 이전 달 버튼 비활성화
                nextMonthButtonDisabled={nextMonthButtonDisabled} // 다음 달 버튼 비활성화
                /* custom functions and variables */
                holidayDates={holidayDates}
                pickUpDate={pickUpDate}
                handleDateChange={handleDateChange}
              />
            );
          }}
        />
      </DatePickerWrapper>
    </StyledDialogRowItem>
  );
}

function formatWeekDay(dayOfWeekLabel) {
  return dayOfWeekLabel.toString().charAt(0);
}

const DatePickerWrapper = styled.div`
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 16px;
    color: ${({ theme }) => theme.color.WHITE};
    background-color: ${({ theme }) => theme.color.BLUE_70};
  }
  .react-datepicker__day-name {
    font-weight: 500;
  }
  .react-datepicker__navigation-icon::before {
    border-color: ${({ theme }) => theme.color.COOL_GRAY_80};
  }
  .react-datepicker__current-month {
    font-weight: 500;
    color: ${({ theme }) => theme.color.COOL_GRAY_90};
  }
  .react-datepicker__day--disabled {
    color: ${({ theme }) => theme.color.COOL_GRAY_50} !important;
    cursor: not-allowed;
  }
`;

const 공휴일정보 = {
  result: {
    2025: {
      1: [2, 5, 15, 16, 17, 31],
      2: [3, 4, 5, 20],
      4: [1, 2, 3, 5, 6, 7, 12, 13, 19, 20, 26, 27, 30, 31],
      5: [1, 2, 3],
      10: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      11: [],
      12: [],
    },
    2026: {
      1: [1, 2, 22, 23, 24],
      2: [5, 6],
      3: [11, 23],
      4: [29],
      5: [30, 31],
      6: [2, 18],
      7: [],
      8: [],
      9: [],
      10: [15],
      11: [],
      12: [25],
    },
  },
};

export default DatePickerInput;
