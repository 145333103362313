import styled from 'styled-components';

import NumberUtil from 'util/NumberUtil';
import DateUtil from 'util/DateUtil';

import DialogRowItem from 'component/ui/dialog/DialogRowItem';

import FormInput from './FormInput';
import AddressFormInput from './AddressFormInput';
import DatePickerInput from './DatePickerInput';

const { getPhoneNumberFormatByPaste, getPhoneNumberFormat } = NumberUtil;

function Content({
  ecgTest,
  parcel,
  formData,
  isEditable,
  onChangeInput,
  handleShowDialog,
}) {
  const { formatDateOnly } = DateUtil;
  const { senderName, senderPhoneNumber, pickUpDate } = formData;

  function onChangePhoneNumber(text, { nativeEvent: { inputType } }) {
    const regex = /^[0-9\b -]{0,13}$/;
    if (!regex.test(text)) return;

    const resultText =
      inputType === 'insertFromPaste'
        ? getPhoneNumberFormatByPaste(text)
        : getPhoneNumberFormat(text);

    onChangeInput(resultText, 'senderPhoneNumber');
  }

  return (
    <DialogContentContainer>
      <FormInput
        label="보내는분"
        placeholder="이름"
        isEditable={isEditable}
        value={senderName}
        onChange={(value) => onChangeInput(value, 'senderName')}
      />

      <FormInput
        label="전화번호"
        placeholder={"'-' 없이 전화번호 입력"}
        isEditable={isEditable}
        value={senderPhoneNumber}
        onChange={onChangePhoneNumber}
      />

      <AddressFormInput
        ecgTest={ecgTest}
        parcel={parcel}
        formData={formData}
        isEditable={isEditable}
        onChangeInput={onChangeInput}
        handleShowDialog={handleShowDialog}
      />

      <DatePickerInput
        isEditable={isEditable}
        pickUpDate={pickUpDate}
        ecgTest={ecgTest}
        onChangeInput={onChangeInput}
      />

      <StyledDialogRowItem label={'검사 기간'}>
        <LabelText>
          {ecgTest.startDatetime && ecgTest.estimatedEndDatetime
            ? `${formatDateOnly(ecgTest.startDatetime)} ~ ${formatDateOnly(
                ecgTest.estimatedEndDatetime
              )}`
            : '-'}
        </LabelText>
      </StyledDialogRowItem>
    </DialogContentContainer>
  );
}

const DialogContentContainer = styled.div`
  margin: 8px 0px 0px;
`;

const StyledDialogRowItem = styled(DialogRowItem)`
  margin: 0 !important;
  padding: 4px 0px;
  box-sizing: border-box;
  height: ${({ height }) => height || '40px'};
  > div {
    color: ${({ theme }) => theme.color.COOL_GRAY_70};
  }
`;

const LabelText = styled.div`
  min-height: 32px;
  word-break: break-all;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${({ theme }) => theme.color.COOL_GRAY_90};
`;

export default Content;
