import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import Const from 'constant/Const';

import { showDialog } from 'redux/duck/dialogDuck';

import { PARCEL_STATUS } from '../../dialog/ManageParcelDialog/ParcelConst';

const { ON_GOING_TEST } = Const.ECG_TEST_STATUS;
const { NONE, CANCELED, PICK_UP_AWAITING, ARRIVAL_AWAITING, UPLOAD_AWAITING } =
  PARCEL_STATUS;

function ReturnTypeButton({ ecgTest, parcel }) {
  const { status } = parcel || {};

  const dispatch = useDispatch();

  if (!parcel) return null;
  if (ecgTest.ecgTestStatus > ON_GOING_TEST && !status) return null;

  const handleShowDialog = (dialogKey, params, callback) => {
    dispatch(showDialog(dialogKey, params, callback));
  };

  const onClickWrapper = (evt) => {
    evt.stopPropagation();
    handleShowDialog('ManageParcelDialog', {
      ecgTest,
      parcel,
      handleShowDialog,
    });
  };

  return (
    <Wrapper onClick={onClickWrapper}>
      <TextWrapper status={status}>
        <Text status={status}>
          {status ? PARCEL_STATUS[status]?.text : NONE.text}
        </Text>
      </TextWrapper>

      {status === ARRIVAL_AWAITING.value && <InquiryText>조회</InquiryText>}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
const TextWrapper = styled.div`
  width: 43px;
  height: 14px;
  padding: 3px 8px;
  border-radius: 12px;
  background-color: ${({ theme, status }) => {
    switch (status) {
      case NONE.value:
        return null;
      case CANCELED.value:
        return theme.color.COOL_GRAY_50;
      case PICK_UP_AWAITING.value:
      case ARRIVAL_AWAITING.value:
        return theme.color.BLUE_70;
      case UPLOAD_AWAITING.value:
        return theme.color.GREEN_80;
      default:
        return theme.color.COOL_GRAY_80;
    }
  }};
  &:hover {
    background-color: ${({ theme, status }) => {
      switch (status) {
        case NONE.value:
          return null;
        case CANCELED.value:
          return theme.color.COOL_GRAY_60;
        case PICK_UP_AWAITING.value:
        case ARRIVAL_AWAITING.value:
          return theme.color.BLUE_80;
        case UPLOAD_AWAITING.value:
          return theme.color.GREEN_90;
        default:
          return theme.color.COOL_GRAY_90;
      }
    }};
  }
`;

const Text = styled.div`
  font-size: 11px;
  font-weight: 500;
  line-height: 130%;
  text-align: center;
  text-decoration-line: ${({ status }) => {
    switch (status) {
      case NONE.value:
        return 'underLine';
      default:
        return 'none';
    }
  }};
  color: ${({ theme, status }) => {
    switch (status) {
      case NONE.value:
        return theme.color.COOL_GRAY_80;
      default:
        return theme.color.WHITE;
    }
  }};
  &:hover {
    color: ${({ theme, status }) => {
      switch (status) {
        case NONE.value:
          return theme.color.COOL_GRAY_90;
        default:
          return theme.color.COOL_GRAY_40;
      }
    }};
  }
`;

const InquiryText = styled.div`
  font-weight: 700;
  font-size: 11px;
  line-height: 14.3px;
  letter-spacing: 0%;
  text-decoration: underline;
  color: ${({ theme }) => theme.color.BLUE_70};
`;

export default ReturnTypeButton;
