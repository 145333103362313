import { useRef, useState, useImperativeHandle, forwardRef } from 'react';
import styled, { css } from 'styled-components';

import Const from 'constant/Const';

import { ErrorIcon } from 'component/ui/icons';

const Wrapper = styled.div`
  display: flex;
  width: 200px;
  background: ${(props) => props.theme.color.WHITE};
  border: 1px solid ${(props) => props.theme.color.COOL_GRAY_50};
  border-radius: 6px;
  position: relative;
  & > * {
    font-size: 12px;
  }
  ${({ customIntervalStyle }) =>
    customIntervalStyle &&
    css`
      ${customIntervalStyle}
    `}
  :focus-within {
    border: 1px solid ${(props) => props.theme.color.BLUE_70};
  }
`;

const StartIconContainer = styled.div`
  position: absolute;
  top: 8px;
  left: 16px;
  :hover {
    cursor: pointer;
  }
  & > * {
    font-size: 20px;
    color: ${(props) => props.theme.color.LIGHT_GREY_BB};
  }
`;

const InputContainer = styled.div`
  width: 100%;
  position: relative;
  ${(props) =>
    props.startMargin &&
    `
        padding-left: 32px;
    `}
  ${(props) =>
    props.endMargin &&
    `
        padding-right: 4px;
    `}
    :hover {
    border-color: ${(props) =>
      !props.isTotalRangeSelected && props.theme.color.PRIMARY};
  }
  ${(props) =>
    props.focused &&
    `
        border-color: ${props.theme.color.PRIMARY};
    `}
  transition: border-color 0.2s ease-in-out;
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;

const StyledInput = styled.input`
  width: calc(100% - 24px);
  padding: 8px 12px;
  background: transparent;
  outline: none;
  border: none;
  font-size: 12px;
  font-family: 'Spoqa Han Sans Neo', 'sans-serif';
  color: ${(props) =>
    props.disabled ? props.theme.color.COOL_GRAY_50 : props.theme.color.BLACK};
  ${({ customIntervalStyle }) =>
    customIntervalStyle &&
    css`
      ${customIntervalStyle}
    `}
`;

const PlaceholderText = styled.div`
  position: absolute;
  top: 5px;
  left: 12px;
  color: ${(props) =>
    props.isTotalRangeSelected
      ? props.theme.color.COOL_GRAY_40
      : props.theme.color.MEDIUM_DARK};
  ${(props) =>
    props.startMargin &&
    `
        padding-left: 32px;
    `}
  ${(props) =>
    props.endMargin &&
    `
        padding-right: 4px;
    `}
`;

const InputAdornment = styled.div`
  display: flex;
  align-items: center;
  padding-right: 8px;
`;

const EndIconContainer = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  margin-right: 8px;
  :hover {
    cursor: pointer;
  }
  svg {
    display: block;
  }
`;

const ErrorMessageWrapper = styled.span`
  display: flex;
  align-items: center;

  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  color: ${({ theme }) => theme.color.RED_70};

  position: absolute;
  left: 10px;
  bottom: -14px;

  & svg {
    width: 12px;
    height: 12px;

    path {
      fill: ${({ theme }) => theme.color.RED_70};
    }
  }
`;

function TextInput(props, ref) {
  const {
    className,
    style,
    customIntervalStyle,
    inputContainerStyle,
    isTotalRangeSelected = false,
    inputType,
    customPlaceholderTextStyle,
    placeholder,
    startIcon,
    onClickStartIcon,
    endIcon,
    onClickEndIcon,
    onSubmit,
    onBlur,
    onFocus,
    adornment,
    disabled,
    min,
    max,
    step,
    showErrorMessage,
    errorMessage,
    maxLength,
    dataTestId,
    readOnly,
  } = props;

  const inputRef = useRef(null);

  const [isInputFocused, setIsInputFocused] = useState(false);

  useImperativeHandle(ref, () => ({
    getInputRef: () => {
      return inputRef.current;
    },
    focus: () => {
      inputRef.current?.focus();
    },
    blur: () => {
      inputRef.current?.blur();
    },
  }));

  const onEnterClick = (event) => {
    if (event.key === Const.KEY_MAP.ENTER) {
      submit();
    }
  };

  const submit = () => {
    if (onSubmit && typeof onSubmit == 'function') {
      onSubmit();
    }
  };

  const displayValue =
    inputType === 'number'
      ? props.value.toString().replace(/^0+/, '')
      : props.value;

  return (
    <Wrapper
      className={className}
      style={style}
      data-testid={dataTestId}
      customIntervalStyle={customIntervalStyle}>
      {!!startIcon && (
        <StartIconContainer onClick={onClickStartIcon || submit}>
          {startIcon}
        </StartIconContainer>
      )}

      <InputContainer
        style={inputContainerStyle}
        className={className}
        startMargin={!!startIcon}
        endMargin={!!endIcon}
        isTotalRangeSelected={isTotalRangeSelected}
        focused={isInputFocused}>
        <StyledInput
          readOnly={readOnly}
          customIntervalStyle={customIntervalStyle}
          disabled={isTotalRangeSelected}
          ref={inputRef}
          type={inputType || 'text'}
          value={displayValue}
          onChange={(event) =>
            props.onChange && props.onChange(event.target.value, event)
          }
          onKeyDown={onEnterClick}
          onFocus={(event) => {
            typeof onFocus === 'function' && onFocus(event);
            setIsInputFocused(true);
          }}
          onBlur={(event) => {
            typeof onBlur === 'function' && onBlur(event);
            setIsInputFocused(false);
          }}
          maxLength={maxLength}
          {..._getNumberInput({
            inputType,
            max,
            min,
            step,
          })}
        />

        {!props.value && placeholder && !disabled && (
          <PlaceholderText
            style={customPlaceholderTextStyle}
            isTotalRangeSelected={isTotalRangeSelected}
            startMargin={!!startIcon}
            endMargin={!!endIcon}
            onClick={() => {
              if (inputRef.current) {
                inputRef.current.focus();
              }
            }}>
            {placeholder}
          </PlaceholderText>
        )}
      </InputContainer>

      {!!adornment && <InputAdornment>{adornment}</InputAdornment>}

      {!!endIcon && (
        <EndIconContainer onClick={onClickEndIcon || submit}>
          {endIcon}
        </EndIconContainer>
      )}

      {showErrorMessage === true && (
        <ErrorMessageWrapper>
          <ErrorIcon />
          {errorMessage}
        </ErrorMessageWrapper>
      )}
    </Wrapper>
  );

  /**
   * inputType이 number인지 확인한 후 number면 step, max, min을 반환해주는함수
   */
  function _getNumberInput({ inputType, step, max, min }) {
    if (inputType !== 'number') return {};

    return {
      step,
      max,
      min,
    };
  }
}

export default forwardRef(TextInput);
