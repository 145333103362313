import { BEAT_TYPE } from './EventConst';

export const CHART_EDIT_CONST = {
  EVENT_MARKER_GROUP: 'huinno-event-marker-group',
  BEAT_MARKER_GROUP: 'huinno-beat-marker-group',
  BEAT_MARKER: 'huinno-beat-marker',
  //
  // pattern matching beat marker(in 30s strip)
  PATTERN_MATCHING_BEAT_MARKER_GROUP:
    'huinno-pattern-matching-beat-marker-group',
  PATTERN_MATCHING_FOUND_BEAT_MARKER:
    'huinno-pattern-matching-found-beat-marker',
  PATTERN_MATCHING_ORIGIN_BEAT_MARKER:
    'huinno-pattern-matching-origin-beat-marker',
  PATTERN_MATCHING_BEAT_MARKER_TEN_SEC_STRIP:
    'pattern-matching-beat-marker-ten-sec-strip',
  // pattern matching event marker(in 30s strip)
  PATTERN_MATCHING_EVENT_MARKER_GROUP:
    'huinno-pattern-matching-event-marker-group',
  PATTERN_MATCHING_EVENT_MARKER: 'pattern-matching-event-marker',
  ///
  // pattern matching rpeak marker
  PATTERN_MATCHING_RPEAK_ICON_GROUP: 'huinno-pattern-matching-rpeak-icon-group',
  PATTERN_MATCHING_RPEAK_GROUP: 'huinno-pattern-matching-rpeak-group',
  PATTERN_MATCHING_RPEAK_RECT: 'huinno-pattern-matching-rpeak-rect',
  PATTERN_MATCHING_RPEAK: 'huinno-pattern-matching-rpeak',
  // pattern matching selection marker
  PATTERN_MATCHING_SELECTION_MARKER: 'huinno-selectionMarker',
  PATTERN_MATCHING_SELECTION_MARKER_TEN_SEC_STRIP:
    'huinno-selectionMarker-TEN_SEC_STRIP',
  //
  HIGHCHARTS_PLOT_BACKGROUND: 'highcharts-plot-background',
  MOUSE_TRACKER: 'huinno-mouse-tracker',
  VERTICAL_DOTTED_LINE: 'huinno-vertical-dotted-line',
  BEAT_ADD_PLUS_BUTTON: 'huinno-beat-add-plus-button',
  VERTICAL_BEAT_PLOT_LINE: 'huinno-clicked-beat-plot-line',
  //
  SELECTION_MARKER: 'SELECTION_MARKER',
  SELECTION_HIGHLIGHT: 'SELECTION_HIGHLIGHT',
  //
  CHART_TYPE: {
    THIRTY_SEC_STRIP: 'THIRTY_SEC_STRIP',
    TEN_SEC_STRIP: 'TEN_SEC_STRIP',
  },
  //
  MAIN_TEN_SEC_STRIP: 'MAIN_TEN_SEC_STRIP',
  EXTRA_TEN_SEC_STRIP: 'EXTRA_TEN_SEC_STRIP',
  REPORT_REPRESENTATIVE_STRIP: 'REPORT_REPRESENTATIVE_STRIP',
  TEN_SEC_STRIP_MOVE_DIR: {
    PREV: 'PREV',
    NEXT: 'NEXT',
  },
};

export const SELECTION_MARKER_TYPE = {
  ONSET: 'ONSET',
  TERMINATION: 'TERMINATION',
  RESET: 'RESET',
  FINDING_RPEAK_TARGET: 'TEN_SEC_STRIP_FINDING_RPEAK_TARGET',
};

export const STRIP_TYPE = {
  SINGLE_LINE: 'SINGLE_LINE',
  MULTI_LINE: {
    ONSET: 'ONSET',
    MIDDLE: 'MIDDLE',
    TERMINATION: 'TERMINATION',
  },
};

export const TEN_SEC_STRIP = {
  TYPE: {
    MAIN: 'main',
    EXTRA: 'extra',
    RESET: 'reset',
  },
  POSITION: {
    PREV: 'PREV',
    NONE: 'NONE',
    NEXT: 'NEXT',
  },
  MOVE_TYPE: {
    PREV: 'PREV',
    NEXT: 'NEXT',
  },
};

export const HIGHCHART_UNIT = {
  LOCATION: 'LOCATION',
  PIXEL: 'PIXEL',
};

export const EDIT_CHART = {
  VALIDATION: {
    EXTEND_SELECTION_STRIP: 'EXTEND_SELECTION_STRIP',
    PREVENT_RENDER_TENSEC_STRIP: 'PREVENT_RENDER_TENSEC_STRIP',
    REMOVE_CONTEXTMENU: 'REMOVE_CONTEXTMENU',
    SHOW_CONTEXTMENU: 'SHOW_CONTEXTMENU',
    REMOVE_SELECTION_HIGHLIGHT_ALL: 'REMOVE_SELECTION_HIGHLIGHT_ALL',
    RENDER_TENSEC_STRIP: 'RENDER_TENSEC_STRIP',
    RENDER_SELECTION_STRIP: 'RENDER_SELECTION_STRIP',
    PREVENT_SECTION_STRIP: 'PREVENT_SECTION_STRIP',
    PREVENT_SHIFT_CLICK: 'PREVENT_SHIFT_CLICK',
  },
};

export const MOUSE_EVENT_TYPE = {
  RIGHT_CLICK_BUTTON: 2,
  LEFT_CLICK_BUTTON: 1,
};

export const REPORT_REPRESENTATIVE_STRIP = {
  TYPE: {
    WHOLELINE: 'WHOLELINE',
    ONELINE: 'ONELINE',
    MULTILINE: {
      BEGIN: 'BEGIN',
      MID: 'MID',
      END: 'END',
    },
  },
};

export const ECG_CHART_UNIT = {
  MS_UNIT_PER_CHART_POINT: 4,
  FIVE_SEC: 5 * 1000,
  TEN_SEC: 10 * 1000,
  FIVE_SEC_WAVEFORM_IDX: (2500 / 10) * 5, // 10초에 2500개 waveform index
  TEN_SEC_WAVEFORM_IDX: 2500,
  HALF_TEN_SEC_WAVEFORM_IDX: 2500 / 2,
  THIRTY_SEC_WAVEFORM_IDX: 7500,
};

export const TEN_SEC_STRIP_EDIT = {
  BEAT_TYPE: ['N', 'S', 'V', 'Q'],
  BEAT_COLOR_TYPE: ['COOL_GRAY_90', 'SVE_70', 'VE_70', 'COOL_GRAY_70'],
};

export const BEAT_BUTTON_COLOR_MAP = {
  [BEAT_TYPE.NORMAL]: 'COOL_GRAY_90',
  [BEAT_TYPE.APC]: 'SVE_70',
  [BEAT_TYPE.VPC]: 'VE_70',
  [BEAT_TYPE.NOISE]: 'COOL_GRAY_70',
};
export const SELECTED_BEAT_BUTTON_STROKE_MAP = {
  [BEAT_TYPE.NORMAL]: 'BLUE_70',
  [BEAT_TYPE.APC]: 'SVE_70',
  [BEAT_TYPE.VPC]: 'VE_70',
  [BEAT_TYPE.NOISE]: 'COOL_GRAY_90',
};
export const SELECTED_BEAT_BUTTON_FILL_MAP = {
  [BEAT_TYPE.NORMAL]: 'BLUE_40',
  [BEAT_TYPE.APC]: 'SVE_40',
  [BEAT_TYPE.VPC]: 'VE_40',
  [BEAT_TYPE.NOISE]: 'COOL_GRAY_50',
};

export const PLOT_LINE_COLOR_MAP = {
  [BEAT_TYPE.NORMAL]: 'BLUE_50',
  [BEAT_TYPE.APC]: 'SVE_50',
  [BEAT_TYPE.VPC]: 'VE_50',
  [BEAT_TYPE.NOISE]: 'COOL_GRAY_50',
};

export const TEN_SEC_STRIP_DETAIL = {
  EDIT_MODE: {
    INIT: 'INIT',
    ADD_BEAT: 'ADD_BEAT',
    CHANGE_BEAT: 'CHANGE_BEAT', // show: beat option button
    TICK_MARKS: 'TICK_MARKS',
  },
  TAB: {
    ARRHYTHMIA_CONTEXTMENU: 'ARRHYTHMIA_CONTEXTMENU',
    HR_REVIEW: 'HR_REVIEW',
    EVENT_REVIEW: 'EVENT_REVIEW',
    BEAT_REVIEW: 'BEAT_REVIEW',
  },
};

export const TEN_SEC_SCRIPT_DETAIL = {
  HR: 'hr',
  BEAT_TYPE: 'beatType',
  WAVEFORM_INDEX: 'waveformIndex',
};

export const AMPLITUDE = {
  TEN_MV: 1,
  TWENTY_MV: 0.5,
};

export const AMPLITUDE_OPTION = {
  FIVE_MV: { LABEL: 5, RATE: 0.5 },
  TEN_MV: { LABEL: 10, RATE: 1 },
  TWENTY_MV: { LABEL: 20, RATE: 2 },
  THIRTY_MV: { LABEL: 30, RATE: 3 },
  FORTY_MV: { LABEL: 40, RATE: 4 },
};

export const getAmplitudeLabelByRate = (rate) => {
  const matchingOption = Object.values(AMPLITUDE_OPTION).find(
    ({ RATE }) => RATE === rate
  );

  return matchingOption?.LABEL;
};

// position 시작: 1, array 시작: 0인이유로 offset이 필요
export const OFFSET_POSITION_ARRAY = 1;

/**
 * @type {rawAndEventCalledCase}
 */
export const rawAndEventCalledCase = {
  KEY_EVENT: 'KEY_EVENT',
  CLICK_EVENT: 'CLICK_EVENT',
  DATA_POLLING: 'DATA_POLLING',
  POSITION_JUMP: 'POSITION_JUMP',
};

/**
 * @typedef {Object} positionMoveType
 * @property {string} INIT
 * @property {string} JUMP
 * @property {string} PREV
 * @property {string} NEXT
 */

/**
 * @type {positionMoveType}
 */
export const POSITION_MOVE_TYPE = {
  INIT: 'INIT ', // side panel event click
  JUMP: 'JUMP', // position move by typing number in input box
  PREV: 'PREV', // position move by left arrow key
  NEXT: 'NEXT', // position move by right arrow key
};

/**
 * @typedef {Object} RawAndEventCalledCase
 * @property {string} CLICK_EVENT
 * @property {string} DATA_POLLING
 * @property {string} POSITION_JUMP
 */

/**
 * @type {RawAndEventCalledCase}
 */
export const getRawAndEventCalledCase = {
  CLICK_EVENT: 'CLICK_EVENT',
  DATA_POLLING: 'DATA_POLLING',
  POSITION_JUMP: 'POSITION_JUMP',
};

export const BEAT_REVIEW_FETCHING_OPTION = {
  BASIS_FETCHING_COUNT: 30,
  BACKWARD_FETCHING_COUNT: 10,
  BASIS_NUMBER_OF_START_POOLING: 20,
  SAMPLE_SIZE: 2500,
  EXTRA_FETCHING_SAMPLE_SIZE: 500,
};

export const BEAT_REVIEW_EVENT_DETAIL_FETCHING_TYPE = {
  INIT: '', // 아래 NEW, PREPARING이 아닌 케이스
  NEW: 'NEW', // position jump시 fetching 받을 때 position이 없는 구간 이동시
  PREPARING: 'PREPARING', // position move시 미리 data를 fetching 받을 때
};

export const SELECTION_MARKER_OFFSET_CONST = {
  CHART: {
    OFFSET_YAXIS: 0.5,
  },
  TENSEC_STRIP: {
    TYPE: CHART_EDIT_CONST.CHART_TYPE.TEN_SEC_STRIP,
    VERTICAL: {
      YAXIS: 30,
      HEIGHT: 28,
    },
    SELECTION_MARKER_TOP: {
      XAXIS: 4,
      YAXIS: 30,
    },
    SELECTION_MARKER_BOTTOM: {
      YAXIS: 20,
      XAXIS: 4,
    },
  },
  THIRTY_SEC_STRIP: {
    TYPE: CHART_EDIT_CONST.CHART_TYPE.THIRTY_SEC_STRIP,
    VERTICAL: {
      YAXIS: 16,
      HEIGHT: 18,
    },
    SELECTION_MARKER_TOP: {
      XAXIS: 4,
      YAXIS: 15,
    },
    SELECTION_MARKER_BOTTOM: {
      YAXIS: 35,
      XAXIS: 4,
    },
  },
};

export const ICON_SVG = {
  STAR: 'M10.9199 15L12.4866 11.5667L15.9199 10L12.4866 8.43333L10.9199 5L9.35325 8.43333L5.91992 10L9.35325 11.5667L10.9199 15Z',
};
