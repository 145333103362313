import { useDispatch } from 'react-redux';

import ResultCode from 'network/ResultCode';

import useShallowEqualSelector from 'component/hook/useShallowEqualSelector';

import { showDialog, hideDialog } from 'redux/duck/dialogDuck';
import ResetPasswordDialog from 'component/dialog/ResetPasswordDialog';
import { resetPasswordByEmailRequested } from 'redux/duck/passwordDuck';

function ResetPasswordDialogContainer(props) {
  const { open, onClose } = props;

  const dispatch = useDispatch();

  const passwordState = useShallowEqualSelector(
    (state) => state.passwordReducer
  );

  const handleShowDialog = (dialogKey, params, callback) => {
    dispatch(showDialog(dialogKey, params, callback));
  };
  const handleHideDialog = (dialogKey) => {
    dispatch(hideDialog(dialogKey));
  };
  const handleResetPasswordByEmailRequested = (email) => {
    dispatch(
      resetPasswordByEmailRequested(email, ResultCode.AUTH_STATUS.LOGIN_SUCCESS)
    );
  };

  return (
    <ResetPasswordDialog
      open={open}
      passwordState={passwordState}
      onClose={onClose}
      showDialog={handleShowDialog}
      hideDialog={handleHideDialog}
      handleResetPasswordByEmailRequested={handleResetPasswordByEmailRequested}
    />
  );
}

export default ResetPasswordDialogContainer;
