import styled from 'styled-components';
import { useEffect, useState } from 'react';

import ApiManager from 'network/ApiManager/ApiManager';

import Label from './Label';
import Timeline from './TimeLine';

interface ParcelStatus {
  rgist: string;
  longitudinalDomesticList: any[];
}

function TrackPackage({ trackingNumber }) {
  const [parcelStatus, setParcelStatus] = useState<ParcelStatus | null>(null);

  useEffect(() => {
    const fetchParcelTrackingNumber = async () => {
      try {
        await ApiManager.getParcelTrackingNumber(trackingNumber, (_, res) => {
          setParcelStatus(res.result.LongitudinalDomesticListResponse);
        });
      } catch (error) {
        console.error(
          'Error occurred while fetching parcel information:',
          error
        );
      }
    };

    fetchParcelTrackingNumber();
  }, []);

  const { rgist, longitudinalDomesticList } = parcelStatus ?? {};

  return (
    <Wrapper>
      <Label
        label={'업체/송장번호'}
        value={`우체국 / ${rgist ?? ''}`}
        height={40}
      />
      {longitudinalDomesticList && (
        <Timeline longitudinalDomesticList={longitudinalDomesticList} />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 290px;
`;

export default TrackPackage;
