import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { MouseEvent, ReactNode } from 'react';

import {
  StyledDropDownIcon,
  ValueText,
  ValueWrapper,
} from 'component/fragment/main/filterDropdown/EcgTestStatusFilterDropdown';

import MenuItem from 'component/ui/menu/MenuItem';
import { MenuBase } from 'component/ui/menu/Menu';
import DropdownBase, {
  CLASS_NAME_DROPDOWN_ANCHOR_ACTIVE,
  CLASS_NAME_DROPDOWN_ANCHOR_HOVER,
} from 'component/ui/dropdown/DropdownBase';
import { CheckIcon, ArrowLeftIcon, ArrowRightIcon } from 'component/ui/icons';

interface Holiday {
  result: {
    [year: string]: {
      [month: string]: number[];
    };
  };
}

interface ParcelDatePickerCustomHeaderProps {
  // standard props from react-datepicker
  changeYear: (year: number) => void;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
  // custom props
  holidayDates: Holiday;
  pickUpDate: string | Date;
  handleDateChange: (date: Date) => void;
  children?: ReactNode;
}

function ParcelDatePickerCustomHeader({
  changeYear,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  holidayDates,
  pickUpDate,
  handleDateChange,
  children,
}: ParcelDatePickerCustomHeaderProps) {
  const intl = useIntl();
  const currentLocale = intl.locale;

  const availableYears = Object.keys(holidayDates).map((year) => Number(year));
  const minYear = Math.min(...availableYears);
  const maxYear = Math.max(...availableYears);

  const selectedYear = new Date(pickUpDate).getFullYear();
  const selectedMonth = new Date(pickUpDate).getMonth();
  const formattedYearMonth = new Date(
    `${selectedYear}-${selectedMonth + 1}`
  ).toLocaleString(currentLocale, {
    year: 'numeric',
    month: 'long',
  });

  const onClickMenuItem = (year: number): void => {
    if (year < minYear || year > maxYear) return;

    const newDate = new Date(pickUpDate);
    newDate.setFullYear(year);
    handleDateChange(newDate);
    changeYear(year);
  };

  const onClickArrowRightIcon = (): void => {
    if (nextMonthButtonDisabled) return;
    if (selectedYear === maxYear && selectedMonth === 11) return;

    const newDate = new Date(pickUpDate);
    newDate.setMonth(newDate.getMonth() + 1);
    handleDateChange(newDate);
    increaseMonth();
  };

  const onClickArrowLeftIcon = (): void => {
    if (prevMonthButtonDisabled) return;
    if (selectedYear === minYear && selectedMonth === 0) return;

    const newDate = new Date(pickUpDate);
    newDate.setMonth(newDate.getMonth() - 1);
    handleDateChange(newDate);
    decreaseMonth();
  };

  return (
    <Wrapper>
      {children}

      <MonthYearSelectorWrapper>
        <ArrowLeftIcon
          onClick={onClickArrowLeftIcon}
          style={{ opacity: prevMonthButtonDisabled ? 0.5 : 1 }}
        />

        <DropdownBase
          anchorProps={{
            style: { padding: '5px', minHeight: '16px', border: 'none' },
          }}
          contentCloseReason="clickAway"
          dropdownDirection="left"
          value={
            <ValueWrapper>
              <ValueText>{formattedYearMonth}</ValueText>
              <StyledDropDownIcon />
            </ValueWrapper>
          }>
          <MenuBase>
            {availableYears.map((year, index) => (
              <MenuItem
                style={{
                  width: 55,
                }}
                key={index}
                onClick={(e: MouseEvent<HTMLElement>) =>
                  onClickMenuItem(Number((e.target as HTMLElement).innerText))
                }>
                <YearLabel>
                  <StyledCheckIcon $show={year === selectedYear} />
                  {year}
                </YearLabel>
              </MenuItem>
            ))}
          </MenuBase>
        </DropdownBase>

        <ArrowRightIcon
          onClick={onClickArrowRightIcon}
          style={{ opacity: nextMonthButtonDisabled ? 0.5 : 1 }}
        />
      </MonthYearSelectorWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const MonthYearSelectorWrapper = styled.div`
  padding: 6px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  svg:hover {
    cursor: pointer;
  }

  & .${CLASS_NAME_DROPDOWN_ANCHOR_HOVER} {
    background-color: ${({ theme }) => theme.color.COOL_GRAY_30};
  }
  & .${CLASS_NAME_DROPDOWN_ANCHOR_ACTIVE} {
    background-color: ${({ theme }) => theme.color.WHITE};
    border-color: ${({ theme }) => theme.color.BLUE_70};
  }
`;

const YearLabel = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  text-align: center;
`;

const StyledCheckIcon = styled(CheckIcon)<{ $show: boolean }>`
  position: absolute;
  left: -3px;
  ${(props) => (props.$show ? '' : 'visibility: hidden;')};
  width: 14px;
  height: 14px;
  & path {
    fill: ${(props) => props.theme.color.COOL_GRAY_70};
  }
`;

export default ParcelDatePickerCustomHeader;
