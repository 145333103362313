import React, { useRef } from 'react';
import styled from 'styled-components';

import { ReactComponent as CheckIcon } from 'static/icon/icon-check-on.svg';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const HiddenCheckBox = styled.input.attrs({ type: 'checkbox' })`
  width: 16px;
  height: 16px;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  opacity: 0;
  cursor: inherit;
`;

const SvgIconCheck = styled(CheckIcon)`
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 0;
  margin: -2.5px;
`;

const StyledCheckbox = styled.div`
  width: 15px;
  height: 15px;
  display: inline-block;
  background-color: ${(props) => props.theme.color.WHITE};
  border: 1.5px solid ${(props) => props.theme.color.MEDIUM};
  border-radius: 4px;
  cursor: inherit;
  position: relative;
  min-width: 15px;
  min-height: 15px;
  ${HiddenCheckBox}:focus {
    box-shadow: 0 0 0 3px pink;
  }
  ${SvgIconCheck} {
    ${(props) => (props.checked ? '' : 'visibility: hidden;')};
  }
`;

const Label = styled.label`
  margin-left: 4px;
  font-size: 11px;
  color: ${(props) => props.theme.color.DARK};
  cursor: inherit;
`;

function CheckBox(props) {
  const {
    //
    checked,
    onChange,
    label,
    labelStyle,
  } = props;

  const checkBoxRef = useRef(null);

  const handleClick = (event) => {
    event.stopPropagation();
    checkBoxRef.current.click();
  };

  return (
    <Wrapper>
      <HiddenCheckBox
        ref={checkBoxRef}
        checked={checked}
        onChange={onChange}
        readOnly={typeof onChange !== 'function'}
      />
      <StyledCheckbox checked={checked} onClick={handleClick}>
        {props.checked && <SvgIconCheck />}
      </StyledCheckbox>

      {label && (
        <Label style={labelStyle} onClick={handleClick}>
          {label}
        </Label>
      )}
    </Wrapper>
  );
}

export default CheckBox;
