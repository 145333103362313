import { useEffect } from 'react';
import { createBrowserHistory } from 'history';
import styled from 'styled-components';

import Const from 'constant/Const';

import DateUtil from 'util/DateUtil';

import useManageParcelFormData from 'component/hook/useManageParcelFormData';

import { DialogWrapper } from 'component/ui/dialog/Dialog';

import { PARCEL_STATUS } from 'component/dialog/ManageParcelDialog/ParcelConst';

import DialogButton from './DialogButton';
import Title from './Title';
import Precaution from './Precaution';
import Content from './Content';
import TrackPackage from './TrackPackage';

const { isSameAsToday } = DateUtil;
const { NONE, RECEIPT_AWAITING } = PARCEL_STATUS;
const editableStatus = new Set(
  [NONE, RECEIPT_AWAITING].map((status) => status.value)
);

function ManageParcelDialog({
  open,
  params: { ecgTest, parcel, handleShowDialog },
  onClose,
  onCancel,
}) {
  const isCreatedToday = !parcel.createdAt || isSameAsToday(parcel.createdAt);
  const isEditable =
    editableStatus.has(parcel.status) &&
    isCreatedToday &&
    ecgTest.ecgTestStatus <= Const.ECG_TEST_STATUS.ON_GOING_TEST;

  const history = createBrowserHistory();
  const {
    location: { pathname },
  } = history;

  const { formData, onChangeInput, isFormModified } = useManageParcelFormData(
    ecgTest,
    parcel
  );

  useEffect(() => {
    if (pathname === '/login') onClose();
  }, [pathname]);

  return (
    <DialogWrapper zIndex={11} open={open}>
      <Title
        isEditable={isEditable}
        status={parcel.status}
        trackingNumber={parcel.trackingNumber}
      />

      <BodyWrapper>
        <ContentWrapper>
          <Content
            ecgTest={ecgTest}
            parcel={parcel}
            formData={formData}
            isEditable={isEditable}
            onChangeInput={onChangeInput}
            handleShowDialog={handleShowDialog}
          />

          <Precaution isEditable={isEditable} isCreatedToday={isCreatedToday} />
        </ContentWrapper>

        {parcel.trackingNumber && <VerticalDivider />}
        {parcel.trackingNumber && (
          <TrackPackage trackingNumber={parcel.trackingNumber} />
        )}
      </BodyWrapper>

      <DialogButton
        ecgTest={ecgTest}
        parcel={parcel}
        formData={formData}
        isEditable={isEditable}
        isFormModified={isFormModified}
        onClose={onClose}
        onCancel={onCancel}
      />
    </DialogWrapper>
  );
}

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const VerticalDivider = styled.div`
  border-right: 1px solid ${({ theme }) => theme.color.COOL_GRAY_40};
`;

export default ManageParcelDialog;
