import { Action } from 'redux';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  ACTION_TYPE,
  FIND_PATTERN_LEVEL_TYPE,
} from 'constant/PatternMatchingConst';

import ApiManager from 'network/ApiManager/ApiManager';

import {
  PatternMatchingInterval,
  PatternMatchingState,
} from '@type/patternMatching/type';

import { includeAction, undoable } from './undoableDuck';
import { initializeState } from './testResultDuck';

// Selector
// Actions
// Action Creators
//
// Initial State
// Reducer
// Saga functions
// Saga

// Selector
export const selectCurrentPatternMatching = (state) =>
  state.patternMatchingDuckReducer;
export const selectEditRange = (state) =>
  state.patternMatchingDuckReducer.editRange;
export const selectPatternMatchingCondition = (state) =>
  state.patternMatchingDuckReducer.patternMatchingCondition;
export const selectEditState = (state) =>
  state.patternMatchingDuckReducer.editState;
// findingPatternTargetRange
export const selectFindingPatternTargetRange = (state) =>
  state.patternMatchingDuckReducer.findingPatternTargetRange;
// similarPatternData
export const selectSimilarPatternData = (state) =>
  state.patternMatchingDuckReducer.similarPatternData;
export const selectSimilarPatternCurrentLevel = (state) =>
  state.patternMatchingDuckReducer.similarPatternData.data.currentLevel;
export const selectSimilarPatternLimitLevel = (state) =>
  state.patternMatchingDuckReducer.similarPatternData.data.limitLevel;
export const selectSimilarPatternDataByLevel = (level) => (state) =>
  state.patternMatchingDuckReducer.similarPatternData.data.levels[
    FIND_PATTERN_LEVEL_TYPE.LEVEL + level
  ];
export const selectSimilarPatternLevel1 = (state) =>
  state.patternMatchingDuckReducer.similarPatternData.data.levels.Level1;
export const selectSimilarPatternLevel2 = (state) =>
  state.patternMatchingDuckReducer.similarPatternData.data.levels.Level2;
// findingRpeakTargetList
export const selectRpeakTargetList = (state) =>
  state.patternMatchingDuckReducer.findingRpeakTargetList;
// editedWaveformIndexes
export const selectEditedWaveformIndexes = (state) =>
  state.patternMatchingDuckReducer.editedWaveformIndexes;
// applyPatternMatchingAction
export const selectApplyPatternMatchingAction = (state) =>
  state.patternMatchingDuckReducer.applyPatternMatchingAction;
// rpeakList
export const selectRpeakList = (state) =>
  state.patternMatchingDuckReducer.rpeakList.data;
// patternAction
export const selectPatternOfAction = (state) =>
  state.patternMatchingDuckReducer.patternAction;
//
const selectEcgTestId = (state) => state.testResultReducer.ecgTestId;

// Actions
// [setting - 변경구간] 패턴 매칭 툴 박스 구간 설정 시 validation
const SET_PATTERN_MATCH_VALIDATION_STATUS =
  'patternMatching/SET_PATTERN_MATCH_VALIDATION_STATUS';
// [setting - 변경구간] 패턴 매칭 툴 박스 전체, 구간 state
const SET_PATTERN_MATCH_UPDATE_SCOPE_TYPE =
  'patternMatching/SET_PATTERN_MATCH_UPDATE_SCOPE_TYPE';
// [setting - 변경구간] 패턴 매칭 툴 박스 구간 input  state
const SET_PATTERN_MATCH_UPDATE_INTERVALS =
  'patternMatching/SET_PATTERN_MATCH_UPDATE_INTERVALS';
// [setting - patternMatching 편집 관련] 패턴 매칭 토글
const SET_TOGGLE_PATTERN_MATCHING =
  'patternMatching/SET_TOGGLE_PATTERN_MATCHING';
// [setting - patternMatching 편집 관련] 패턴 찾기 대상 범위 설정(유사 패턴 찾기, 유사패턴에서 제외할 패턴 찾기에서 사용)
const SET_FINDING_PATTERN_TARGET_RANGE =
  'patternMatching/SET_FINDING_PATTERN_TARGET_RANGE';
// [state setting] 첫번째 유사 패턴에서 두번째 유사패턴 제외 후 첫번째 유사 패턴 state로 세팅
const SET_EXCLUSION_PATTERN = 'patternMatching/GET_EXCLUSION_PATTERN_REQUEST';
// [setting - patternMatching 편집 관련] Rpeak 설정하기 모드
const SET_TOGGLE_RPEAK = 'patternMatching/SET_TOGGLE_RPEAK';
// [setting - patternMatching 편집 관련] 찾을 Rpeak target 설정
const SET_FINDING_RPEAK_TARGET_LIST =
  'patternMatching/SET_FINDING_RPEAK_TARGET_LIST';
// [setting - patternMatching 편집 관련] rpeak update option(비트 타입 선택, remove 선택)
const SET_RPEAK_UPDATE_OPTION = 'patternMatching/SET_RPEAK_UPDATE_OPTION';
// [setting - patternMatching 편집 관련] edit state update
const SET_EDIT_STATE = 'patternMatching/SET_EDIT_STATE';
// [setting - patternMatching 편집 관련] snack bar 용 patternAction Update
const SET_INIT_PATTERN_OF_ACTION_UPDATE =
  'patternMatching/SET_INIT_PATTERN_OF_ACTION_UPDATE';
// [setting - patternMatching 편집 관련] snack bar 용 patternAction Update
const SET_PATTERN_OF_ACTION_UPDATE =
  'patternMatching/SET_PATTERN_OF_ACTION_UPDATE';
// [api] 유사 패턴 찾기
const GET_SIMILAR_PATTERN_REQUEST =
  'patternMatching/GET_SIMILAR_PATTERN_REQUEST';
const GET_SIMILAR_PATTERN_SUCCESS =
  'patternMatching/GET_SIMILAR_PATTERN_SUCCESS';
const GET_SIMILAR_PATTERN_FAILED = 'patternMatching/GET_SIMILAR_PATTERN_FAILED';
// [api] Rpeak 찾기
const GET_RPEAK_REQUEST = 'patternMatching/GET_RPEAK_REQUEST';
const GET_RPEAK_SUCCESS = 'patternMatching/GET_RPEAK_SUCCESS';
const GET_RPEAK_FAILED = 'patternMatching/GET_RPEAK_FAILED';
// 편집 요소 key update func
const GET_RPEAK_UPDATE_OPTION_REQUEST =
  'patternMatching/GET_RPEAK_UPDATE_OPTION_REQUEST';
// [api] 적용하기
const POST_PATTERN_MATCHING_REQUEST =
  'patternMatching/POST_PATTERN_MATCHING_REQUEST';
const POST_PATTERN_MATCHING_SUCCESS =
  'patternMatching/POST_PATTERN_MATCHING_SUCCESS';
const POST_PATTERN_MATCHING_FAILED =
  'patternMatching/POST_PATTERN_MATCHING_FAILED';

// Action Creators
// [setting - 변경구간] 패턴 매칭 툴 박스 구간 설정 시 validation
export function setPatternMatchValidationStatus(payload) {
  return {
    type: SET_PATTERN_MATCH_VALIDATION_STATUS,
    payload,
  };
}
// [setting - 변경구간] 패턴 매칭 툴 박스 전체, 구간 state
export function setPatternMatchUpdateScopeType(payload) {
  return {
    type: SET_PATTERN_MATCH_UPDATE_SCOPE_TYPE,
    payload,
  };
}
// [setting - 변경구간] 패턴 매칭 툴 박스 구간 input state
export function setPatternMatchUpdateIntervals(payload) {
  return {
    type: SET_PATTERN_MATCH_UPDATE_INTERVALS,
    payload,
  };
}
// [setting - patternMatching 편집 관련] 패턴 매칭 토글
export const setTogglePatternMatching = (
  isPatternMatchingEnabled: boolean
) => ({
  type: SET_TOGGLE_PATTERN_MATCHING,
  payload: { isPatternMatchingEnabled },
});
// [setting - patternMatching 편집 관련] 패턴 찾기 대상 범위 설정(유사 패턴 찾기, 유사패턴에서 제외할 패턴 찾기에서 사용)
export const setFindingPatternTargetRange = ({
  onsetWaveformIndex,
  terminationWaveformIndex,
}) => ({
  type: SET_FINDING_PATTERN_TARGET_RANGE,
  payload: {
    onsetWaveformIndex,
    terminationWaveformIndex,
  },
});
// [setting - patternMatching 편집 관련] 유사 패턴에서 제외 하기 기능(첫번째 유사패턴에서 두번째 유사패턴 제외)
export const SetExclusionPattern = () => ({
  type: SET_EXCLUSION_PATTERN,
  payload: {},
});
// [setting - patternMatching 편집 관련] Rpeak 설정하기 모드
export const setToggleRpeak = (payload) => ({
  type: SET_TOGGLE_RPEAK,
  payload,
});
// [setting - patternMatching 편집 관련] 찾을 Rpeak target 설정
export const setFindingRpeakTargetList = ({ rpeakTargetWaveformIndex }) => ({
  type: SET_FINDING_RPEAK_TARGET_LIST,
  payload: {
    rpeakTargetWaveformIndex,
  },
});
// [setting - patternMatching 편집 관련] rpeak update option(비트 타입 선택, remove 선택)
export const setRpeakUpdateOption = (option) => {
  return {
    type: SET_RPEAK_UPDATE_OPTION,
    payload: option,
  };
};
// [setting - patternMatching 편집 관련] editState update
export const setEditState = ({ editStateField }) => {
  return {
    type: SET_EDIT_STATE,
    payload: { editStateField },
  };
};
// [setting - patternMatching 편집 관련] snack bar 용 patternAction Update
export const setInitPatternOfActionUpdate = (
  initUpdateField: string[] = []
) => {
  return {
    type: SET_INIT_PATTERN_OF_ACTION_UPDATE,
    payload: { initUpdateField },
  };
};
// [setting - patternAction Update]
export const setPatternOfActionUpdate = (targetPatternOfAction: string) => {
  return {
    type: SET_PATTERN_OF_ACTION_UPDATE,
    payload: { targetPatternOfAction },
  };
};
// [api] 유사 패턴 찾기
export const getSimilarPatternRequest = ({
  patternOnsetWaveformIndex,
  patternTerminationWaveformIndex,
  editSectionOnsetWaveformIndex,
  editSectionTerminationWaveformIndex,
  similarity,
}) => {
  return {
    type: GET_SIMILAR_PATTERN_REQUEST,
    payload: {
      patternOnsetWaveformIndex,
      patternTerminationWaveformIndex,
      editSectionOnsetWaveformIndex,
      editSectionTerminationWaveformIndex,
      similarity,
    },
  };
};
export const getSimilarPatternSuccess = ({
  similarPatternList,
  patternOnsetWaveformIndexes,
  patternTerminationWaveformIndexes,
  beatCountInPattern,
  patternOnsetWaveformIndex,
  patternTerminationWaveformIndex,
}) => ({
  type: GET_SIMILAR_PATTERN_SUCCESS,
  payload: {
    similarPatternList,
    patternOnsetWaveformIndexes,
    patternTerminationWaveformIndexes,
    beatCountInPattern,
    patternOnsetWaveformIndex,
    patternTerminationWaveformIndex,
  },
});
export const getSimilarPatternFailed = (error: unknown) => ({
  type: GET_SIMILAR_PATTERN_FAILED,
  payload: { error },
});
// [api] Rpeak 찾기
export const getRpeakRequest = (findingRpeakTargetList) => {
  return {
    type: GET_RPEAK_REQUEST,
    payload: { findingRpeakTargetList },
  };
};
export const getRpeakSuccess = (rpeakList) => ({
  type: GET_RPEAK_SUCCESS,
  payload: { rpeakList },
});
export const getRpeakFailed = (error: unknown) => ({
  type: GET_RPEAK_FAILED,
  payload: { error },
});
// [api] 적용하기
export const applyPatternMatchingRequest = () => {
  return {
    type: POST_PATTERN_MATCHING_REQUEST,
  };
};
export const applyPatternMatchingSuccess = (editedWaveformIndexes) => {
  return {
    type: POST_PATTERN_MATCHING_SUCCESS,
    payload: { editedWaveformIndexes },
  };
};
export const applyPatternMatchingFailed = (error: unknown) => ({
  type: POST_PATTERN_MATCHING_FAILED,
  payload: { error },
});

// Initial State
export const initFindingPatternTargetRange = {
  onsetWaveformIndex: undefined,
  terminationWaveformIndex: undefined,
};
const initialState: PatternMatchingState = {
  // 패턴 매칭 조건 상태(라디오 버튼, 시간 영역 인풋)
  editRange: {
    searchCondition: {
      editSectionIntervals: [],
      isTotalRangeSelected: false,
    },
    validationStatus: {
      editSectionIntervals: false,
      startValid: false,
      endValid: false,
    },
  },
  // 편집 상태
  editState: {
    isPatternMatchingEnabled: false,
    isFindSimilarPattern: false,
    isExcludeSimilarPattern: false,
    isSetRpeak: false,
    isFindRpeak: false,
  },
  // 편집 대상 유사패턴 범위
  findingPatternTargetRange: initFindingPatternTargetRange,
  // n번째 찾은 유사패턴
  similarPatternData: {
    data: {
      limitLevel: 2,
      currentLevel: 0,
      beatCountInPattern: 0,
      beatCountInPatternLevel1: 0,
      beatCountInPatternLevel2: 0,
      //Level은 1부터 시작이고 limitLevel까지 존재하며 Level1,Level2은 없을 수도 있습니다.
      levels: {},
    },
    pending: false,
    error: null,
  },
  // Rpeak 찾기 대상
  findingRpeakTargetList: [],
  // Rpeak 찾은 결과
  rpeakList: {
    data: [],
    pending: false,
    error: null,
  },
  patternAction: ACTION_TYPE.INIT,
  // pattern matching으로 편집한 결과
  editedWaveformIndexes: {
    data: [],
    pending: false,
    error: null,
  },
  //
  applyPatternMatchingAction: {
    beatType: null,
    actionType: null,
    error: null,
  },
};
// Reducer
function reducer(
  state = initialState,
  action: Action & { payload?: any }
): PatternMatchingState {
  switch (action.type) {
    // [setting - 변경구간] 패턴 매칭 툴 박스 구간 설정 시 validation
    case SET_PATTERN_MATCH_VALIDATION_STATUS: {
      const { validationStatus } = action.payload;
      return {
        ...state,
        editRange: {
          ...state.editRange,
          validationStatus,
        },
      };
    }
    // [setting - 변경구간] 패턴 매칭 툴 박스 전체, 구간 state
    case SET_PATTERN_MATCH_UPDATE_SCOPE_TYPE: {
      const { searchCondition } = action.payload;
      const { isTotalRangeSelected } = searchCondition;
      return {
        ...state,
        editRange: {
          ...state.editRange,
          searchCondition: {
            ...state.editRange.searchCondition,
            ...searchCondition,
            ...(isTotalRangeSelected && {
              editSectionIntervals: [],
            }),
          },
          validationStatus: {
            ...state.editRange.validationStatus,
            ...(!isTotalRangeSelected && {
              editSectionIntervals: false,
              startValid: false,
              endValid: false,
            }),
          },
        },
      };
    }
    // [setting - 변경구간] 패턴 매칭 툴 박스 구간 input state
    case SET_PATTERN_MATCH_UPDATE_INTERVALS: {
      const {
        editSectionIntervals,
      }: { editSectionIntervals: PatternMatchingInterval } = action.payload;
      return {
        ...state,
        editRange: {
          ...state.editRange,
          searchCondition: {
            ...state.editRange.searchCondition,
            editSectionIntervals: [...editSectionIntervals],
          },
        },
      };
    }
    // [setting - patternMatching 편집 관련] 패턴 매칭 토글
    case SET_TOGGLE_PATTERN_MATCHING: {
      const patternAction = ACTION_TYPE.INIT;
      return {
        ...state,
        editState: {
          ...state.editState,
          isPatternMatchingEnabled: action.payload.isPatternMatchingEnabled,
        },
        patternAction,
      };
    }
    // [setting - patternMatching 편집 관련] 패턴 찾기 대상 범위 설정(유사 패턴 찾기, 유사패턴에서 제외할 패턴 찾기에서 사용)
    case SET_FINDING_PATTERN_TARGET_RANGE: {
      let { onsetWaveformIndex, terminationWaveformIndex } = action.payload;
      return {
        ...state,
        findingPatternTargetRange: {
          ...state.findingPatternTargetRange,
          onsetWaveformIndex,
          terminationWaveformIndex,
        },
      };
    }
    // [setting - patternMatching 편집 관련] 유사 패턴에서 제외 하기 기능(첫번째 유사패턴에서 두번째 유사패턴 제외)
    case SET_EXCLUSION_PATTERN: {
      // similarPatternData.data.levels 속성에 Level1, Level2가 존재하는데 이중에서 Level1에서 Level2구간이 포함되어 있는 구간을 제외
      // 그리고 찾은 데이터를 state.similarPatternData.data.levels.Level1에 세팅
      // 첫번째 유사패턴
      const level1 =
        state.similarPatternData.data.levels.Level1.similarPatternList;
      // 두번째  유사패턴
      const level2 =
        state.similarPatternData.data.levels.Level2.similarPatternList;
      // 첫번째 대표 유사 패턴 range
      const {
        onsetWaveformIndex: originLevel1OnsetWaveformIndex,
        terminationWaveformIndex: originLevel1TerminationWaveformIndex,
      } = state.similarPatternData.data.levels.Level1.targetRange;
      // patternMatching action 상태
      const patternAction = ACTION_TYPE.EXCLUSION;

      if (level1 && level2) {
        // level1에 level2가 없는 요소 필터링
        const level2Set = new Set(level2.map(createKey));
        const exclusionPatternOnset = [] as number[];
        const exclusionPatternTermination = [] as number[];
        const exclusionPatternList = level1.filter((pair) => {
          const isExcluded =
            !level2Set.has(createKey(pair)) ||
            (pair[0] === originLevel1OnsetWaveformIndex &&
              pair[1] === originLevel1TerminationWaveformIndex);
          if (isExcluded) {
            exclusionPatternOnset.push(pair[0]);
            exclusionPatternTermination.push(pair[1]);
          }
          return isExcluded;
        });

        function createKey(pair) {
          return pair.join(',');
        }

        // exclusionPatternList 구간에 rpeak가 있는 경우 해당 rpeak는 rpeakList에서 제거
        const rpeakList = state.rpeakList.data;

        const newRpeakList = rpeakList.filter((rpeak) => {
          return !level2.some(([start, end]) => {
            const isRpeakInLevel2 = start <= rpeak && rpeak <= end;
            return isRpeakInLevel2;
          });
        });
        // todo: jyoon [#patternMatching #refactoring] 아래 로직 파악 필요
        if (exclusionPatternList.length === 0) {
          return {
            ...initialState,
            patternAction,
            editState: { ...state.editState },
          };
        }

        return {
          ...state,
          similarPatternData: {
            ...state.similarPatternData,
            data: {
              ...state.similarPatternData.data,
              currentLevel: 1,
              levels: {
                Level1: {
                  ...state.similarPatternData.data.levels.Level1,
                  similarPatternList: exclusionPatternList,
                  patternOnsetWaveformIndexes: exclusionPatternOnset,
                  patternTerminationWaveformIndexes:
                    exclusionPatternTermination,
                },
              },
            },
          },
          rpeakList: {
            ...state.rpeakList,
            data: newRpeakList,
          },
          patternAction,
        };
      }
      return state;
    }
    // [setting - patternMatching 편집 관련] Rpeak 설정하기 모드
    case SET_TOGGLE_RPEAK: {
      const isSetRpeak =
        action.payload === undefined
          ? !state.editState.isSetRpeak
          : action.payload;

      return {
        ...state,
        editState: {
          ...state.editState,
          isSetRpeak,
        },
        // findingRpeakTargetList,
      };
    }
    // [setting - patternMatching 편집 관련] 찾을 Rpeak target 설정
    case SET_FINDING_RPEAK_TARGET_LIST: {
      // patternMatching action 상태
      const patternAction = ACTION_TYPE.SET_RPEAK;
      return {
        ...state,
        findingRpeakTargetList: [
          ...state.findingRpeakTargetList,
          action.payload.rpeakTargetWaveformIndex,
        ],
        patternAction,
      };
    }
    // [setting - patternMatching 편집 관련] rpeak update option(비트 타입 선택, remove 선택)
    case SET_RPEAK_UPDATE_OPTION: {
      const { beatType = null, actionType } = action.payload;

      return {
        ...state,
        applyPatternMatchingAction: {
          ...state.applyPatternMatchingAction,
          beatType,
          actionType,
        },
        patternAction: actionType,
      };
    }
    // [setting - patternMatching 편집 관련] editState update
    case SET_EDIT_STATE: {
      return {
        ...state,
        editState: {
          ...state.editState,
          ...action.payload.editStateField,
        },
      };
    }
    // [setting - patternMatching 편집 관련] snack bar 용 patternAction Update
    case SET_INIT_PATTERN_OF_ACTION_UPDATE: {
      const initUpdateField = action.payload?.initUpdateField ?? false;

      if (initUpdateField.includes('initialState')) {
        return {
          ...initialState,
        };
      }

      let updatedState = {};
      initUpdateField.forEach((field) => {
        updatedState = {
          ...updatedState,
          [field]: initialState[field],
        };
      });

      return {
        ...state,
        ...updatedState,
      };
    }
    // [setting - similarPatternData 초기화]
    case SET_PATTERN_OF_ACTION_UPDATE: {
      const { targetPatternOfAction } = action.payload;
      return {
        ...state,
        patternAction: targetPatternOfAction,
      };
    }
    // [api] 유사 패턴 찾기
    case GET_SIMILAR_PATTERN_REQUEST: {
      return {
        ...state,
        similarPatternData: {
          ...state.similarPatternData,
          pending: true,
        },
      };
    }
    case GET_SIMILAR_PATTERN_SUCCESS: {
      const {
        patternOnsetWaveformIndexes,
        patternTerminationWaveformIndexes,
        beatCountInPattern,
        patternOnsetWaveformIndex,
        patternTerminationWaveformIndex,
      } = action.payload;
      const currentLevel = state.similarPatternData.data.currentLevel;
      const targetRange = {
        onsetWaveformIndex: patternOnsetWaveformIndex,
        terminationWaveformIndex: patternTerminationWaveformIndex,
      };
      const similarPatternList = action.payload.similarPatternList;
      const nextLevel = currentLevel + 1;
      const updatedLevels = {
        ...state.similarPatternData.data.levels,
        [`Level${nextLevel}`]: {
          targetRange,
          similarPatternList,
          patternOnsetWaveformIndexes,
          patternTerminationWaveformIndexes,
        },
      };
      const patternAction = ACTION_TYPE[`FIND_LEVEL${nextLevel}`];

      const newState = {
        findingPatternTargetRange: {
          onsetWaveformIndex: undefined,
          terminationWaveformIndex: undefined,
        },
        similarPatternData: {
          ...state.similarPatternData,
          data: {
            ...state.similarPatternData.data,
            currentLevel: nextLevel,
            levels: updatedLevels,
            beatCountInPattern,
            [`beatCountInPatternLevel${nextLevel}`]:
              patternOnsetWaveformIndexes.length,
          },
          pending: false,
        },
      };

      return {
        ...state,
        findingPatternTargetRange: newState.findingPatternTargetRange,
        similarPatternData: newState.similarPatternData,
        patternAction,
      };
    }
    case GET_SIMILAR_PATTERN_FAILED: {
      return {
        ...state,
        editState: {
          ...state.editState,
          isFindSimilarPattern: false,
        },
        similarPatternData: {
          ...state.similarPatternData,
          pending: false,
        },
      };
    }
    // [api] Rpeak 찾기
    case GET_RPEAK_REQUEST: {
      return {
        ...state,
        rpeakList: {
          ...state.rpeakList,
          pending: true,
        },
      };
    }
    case GET_RPEAK_SUCCESS: {
      const patternAction = ACTION_TYPE.GET_RPEAK;
      return {
        ...state,
        rpeakList: {
          ...state.rpeakList,
          data: action.payload.rpeakList,
          pending: false,
        },
        findingRpeakTargetList: [],
        editState: {
          ...state.editState,
          isFindRpeak: true,
          isSetRpeak: false,
        },
        patternAction,
        applyPatternMatchingAction: {
          ...state.applyPatternMatchingAction,
          beatType: null,
          actionType: null,
        },
      };
    }
    case GET_RPEAK_FAILED: {
      return {
        ...state,
        rpeakList: {
          ...state.rpeakList,
          pending: false,
          error: action.payload.error,
        },
      };
    }
    case GET_RPEAK_UPDATE_OPTION_REQUEST: {
      const { beatType = null, actionType } = action.payload;
      return {
        ...state,
        applyPatternMatchingAction: {
          ...state.applyPatternMatchingAction,
          beatType,
          actionType,
        },
        patternAction: actionType,
      };
    }
    // [api] 적용하기
    case POST_PATTERN_MATCHING_REQUEST: {
      return {
        ...state,
        editedWaveformIndexes: {
          ...state.editedWaveformIndexes,
          pending: true,
        },
      };
    }
    case POST_PATTERN_MATCHING_SUCCESS: {
      const { editedWaveformIndexes: editedWaveformIndexList } = action.payload;
      return {
        ...state,
        editRange: {
          ...state.editRange,
        },
        editedWaveformIndexes: {
          ...state.editedWaveformIndexes,
          data: editedWaveformIndexList,
          pending: false,
        },
      };
    }
    case POST_PATTERN_MATCHING_FAILED: {
      return {
        ...state,
        editedWaveformIndexes: {
          ...state.editedWaveformIndexes,
          pending: false,
          error: action.payload.error,
        },
      };
    }
    default: {
      return state;
    }
  }
}

// todo: jyoon [#patternMatching #refactoring] limit 초기화 해야함
export default undoable(reducer, {
  limit: 5,
  filter: includeAction([
    GET_SIMILAR_PATTERN_SUCCESS,
    SET_EXCLUSION_PATTERN,
    SET_FINDING_RPEAK_TARGET_LIST,
    SET_TOGGLE_RPEAK,
    SET_FINDING_PATTERN_TARGET_RANGE,
    GET_RPEAK_SUCCESS,
  ]),
});

function* _setTogglePatternMatching(action) {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    // response - Null
    yield call(ApiManager.getPatternMatchingModeToggle, ecgTestId);
  } catch (error) {
    yield put(getRpeakFailed(error));
  }
}
function* _getSimilarPattern(action) {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    const {
      patternOnsetWaveformIndex,
      patternTerminationWaveformIndex,
      editSectionOnsetWaveformIndex,
      editSectionTerminationWaveformIndex,
      similarity,
    } = action.payload;

    const similarPatternCurrentLevel = yield select(
      selectSimilarPatternCurrentLevel
    );

    let resultList;
    if (similarPatternCurrentLevel === 0) {
      const {
        data: { result },
      } = yield call(ApiManager.getPatternMatchingSimilarPattern, ecgTestId, {
        patternOnsetWaveformIndex,
        patternTerminationWaveformIndex,
        editSectionOnsetWaveformIndex,
        editSectionTerminationWaveformIndex,
        similarity,
      });
      resultList = result;
    } else {
      const selectSimilarPatternDataLevel1 = yield select(
        selectSimilarPatternDataByLevel(1)
      );
      const {
        patternOnsetWaveformIndexes: selectedPatternOnsetWaveformIndexes,
        patternTerminationWaveformIndexes:
          selectedPatternTerminationWaveformIndexes,
      } = selectSimilarPatternDataLevel1;

      const {
        data: { result },
      } = yield call(ApiManager.getPatternMatchingFilterPattern, ecgTestId, {
        patternOnsetWaveformIndexes: selectedPatternOnsetWaveformIndexes,
        patternTerminationWaveformIndexes:
          selectedPatternTerminationWaveformIndexes,
        patternOnsetWaveformIndex,
        patternTerminationWaveformIndex,
        similarity,
      });
      resultList = result;
    }

    const {
      patternOnsetWaveformIndexes,
      patternTerminationWaveformIndexes,
      beatCountInPattern,
    } = resultList;

    const similarPatternList = resultList.patternOnsetWaveformIndexes.map(
      (onset, index) => [
        onset,
        resultList.patternTerminationWaveformIndexes[index],
      ]
    );

    yield put(
      getSimilarPatternSuccess({
        similarPatternList: similarPatternList,
        patternOnsetWaveformIndexes,
        patternTerminationWaveformIndexes,
        beatCountInPattern,
        patternOnsetWaveformIndex,
        patternTerminationWaveformIndex,
      })
    );
  } catch (error) {
    yield put(getSimilarPatternFailed(error));
  }
}

function* _getRpeak(action) {
  try {
    const { findingRpeakTargetList } = action.payload;
    const ecgTestId = yield select(selectEcgTestId);

    const selectSimilarPatternDataLevel1 = yield select(
      selectSimilarPatternDataByLevel(1)
    );
    const { onsetWaveformIndex, terminationWaveformIndex } =
      selectSimilarPatternDataLevel1.targetRange;

    const patternOnsetWaveformIndexes =
      selectSimilarPatternDataLevel1.patternOnsetWaveformIndexes;
    const patternTerminationWaveformIndexes =
      selectSimilarPatternDataLevel1.patternTerminationWaveformIndexes;

    const {
      data: { result },
    } = yield call(ApiManager.getPatternMatchingFindRPeak, ecgTestId, {
      patternOnsetWaveformIndexes,
      patternTerminationWaveformIndexes,
      patternOnsetWaveformIndex: onsetWaveformIndex,
      patternTerminationWaveformIndex: terminationWaveformIndex,
      addingRPeakWaveformIndexes: findingRpeakTargetList,
    });
    const { waveformIndexes: rpeakList } = result;
    // Rpeak 찾기 api 호출
    yield put(getRpeakSuccess(rpeakList));
  } catch (error) {
    yield put(getRpeakFailed(error));
  }
}
function* _applyPatternMatchingRequest() {
  try {
    const ecgTestId = yield select(selectEcgTestId);
    yield put(initializeState(ecgTestId));

    const { beatType /*, actionType */ } = yield select(
      selectApplyPatternMatchingAction
    );
    const similarPatternCurrentLevel = yield select(
      selectSimilarPatternCurrentLevel
    );
    const selectSimilarPatternDataLevel1 = yield select(
      selectSimilarPatternDataByLevel(1)
    );
    const selectSimilarPatternDataLevel2 = yield select(
      selectSimilarPatternDataByLevel(2)
    );
    const rpeakList = yield select(selectRpeakList);

    // ACTION_TYPE
    const source =
      similarPatternCurrentLevel === 1
        ? selectSimilarPatternDataLevel1
        : selectSimilarPatternDataLevel2;

    const patternOnsetWaveformIndexes = source.patternOnsetWaveformIndexes;
    const patternTerminationWaveformIndexes =
      source.patternTerminationWaveformIndexes;

    const hasRpeakList = rpeakList.length > 0;
    const editBeatType = beatType >= 0 && beatType <= 3;

    let response;

    if (hasRpeakList && editBeatType) {
      // 패턴 찾은 이후, rpeakList와 비트 타입이 있는 경우
      const {
        data: { result },
      } = yield call(ApiManager.getPatternMatchingAdd, ecgTestId, {
        patternOnsetWaveformIndexes,
        patternTerminationWaveformIndexes,
        beatType,
        waveformIndexes: rpeakList,
      });
      response = result;
    } else if (editBeatType) {
      // 패턴 찾은 이후, rpeakList는 없이 비트 타입만 있는 경우
      const {
        data: { result },
      } = yield call(ApiManager.getPatternMatchingUpdate, ecgTestId, {
        patternOnsetWaveformIndexes,
        patternTerminationWaveformIndexes,
        beatType,
      });
      response = result;
    } else if (!editBeatType) {
      // 패턴만 찾은 경우 삭제
      const {
        data: { result },
      } = yield call(ApiManager.getPatternMatchingDelete, ecgTestId, {
        patternOnsetWaveformIndexes,
        patternTerminationWaveformIndexes,
      });
      response = result;
    }
    const { editedWaveformIndexes } = response;

    yield put(applyPatternMatchingSuccess(editedWaveformIndexes));
  } catch (error) {
    yield put(applyPatternMatchingFailed(error));
  }
}

// function* _setRpeakUpdateOption() {
//   console.log('fn* _setRpeakUpdateOption');
// }

// Saga
export function* saga() {
  yield takeLatest(SET_TOGGLE_PATTERN_MATCHING, _setTogglePatternMatching);
  yield takeLatest(GET_SIMILAR_PATTERN_REQUEST, _getSimilarPattern);
  yield takeLatest(GET_RPEAK_REQUEST, _getRpeak);
  yield takeLatest(POST_PATTERN_MATCHING_REQUEST, _applyPatternMatchingRequest);
  // yield takeLatest(SET_RPEAK_UPDATE_OPTION, _setRpeakUpdateOption);
}
